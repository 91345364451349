import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Navbar } from "./components/navbar/navigation";
import { Home } from "./Page/home/home";
import { Footer } from "./components/footer/footer";
/* nosotros */
import Nuestroequipo from "./Page/Nuestroequipo/page";
import Nosotrosmap from "./Page/Nosotros/Mapa/page";
import Nosotrosprop from "./Page/Nosotros/Propositos/page";
import Nosotrospers from "./Page/Nosotros/Personas/page";
import Nosotroshist from "./Page/Nosotros/Historia/page";
import Nosotrosport from "./Page/Nosotros/Portada/page";
/* servicios */
import Mantenimiento from "./Page/Servicios/Mantenimiento/page";
import Manejosolidos from "./Page/Servicios/ManejoSolidos/page";
import Limpieza from "./Page/Servicios/Limpieza/page";
import Limpiezaindustrial from "./Page/Servicios/Limpiezaindustrial/page";
import Soluciones from "./Page/Servicios/Soluciones/page";
import Logistica from "./Page/Servicios/Logistica/page";
import Confianza from "./Page/Servicios/Confianza/page";
import Equipo from "./Page/Servicios/Equipo/page";
/* Seguridad */
import Seguridadestandare from "./Page/Seguridad/Estandar/page";
import Seguridadprot from "./Page/Seguridad/Proteccion/page";
import Seguridadfocos from "./Page/Seguridad/Foco/page";
import SeguridadGaleria from "./Page/Seguridad/Galerias/page";
import Seguridadini from "./Page/Seguridad/Iniciativa/page";
import Seguridadpor from "./Page/Seguridad/Portada/page";
import Seguridadreglas from "./Page/Seguridad/Regla/page";


/*sostenibilidad */
import Sostenibilidadgal from "./Page/Sostenibilidad/Galeria/page";
import Sostenibilidadinclu from "./Page/Sostenibilidad/Inclusion/page";
import Sostenibilidadport from "./Page/Sostenibilidad/Portada/page";
import Sostenibilidadrel from "./Page/Sostenibilidad/Relacionamiento/page";




const Layout = () => {
    const basename = process.env.BASENAME || "";

    
  return (
    <BrowserRouter basename={basename}>
       <Navbar/>
        <Routes> 
         <Route element={<Home/>} path="/" /> 
         <Route element={<Nosotrosmap/>} path="/Nosotros/Mapa" /> 
         <Route element={<Nosotrospers/>} path="/Nosotros/Personas" /> 
         <Route element={<Nosotrosprop/>} path="/Nosotros/Propositos" /> 
         <Route element={<Nosotroshist/>} path="/Nosotros/Historia" /> 
         <Route element={<Nosotrosport/>} path="/Nosotros/Portada" /> 
         <Route element={<Nuestroequipo/>} path="/Nuestroequipo" /> 
         <Route element={<Limpieza/>} path="/Servicios/Limpieza" /> 
         <Route element={<Manejosolidos/>} path="/Servicios/ManejoSolidos" /> 
         <Route element={<Logistica/>} path="/Servicios/Logistica" /> 
         <Route element={<Limpiezaindustrial/>} path="/Servicios/Limpiezaindustrial" /> 
         <Route element={<Mantenimiento/>} path="/Servicios/Mantenimiento" /> 
         <Route element={<Soluciones/>} path="/Servicios/Soluciones" /> 
         <Route element={<Equipo/>} path="/Servicios/Equipo" /> 
         <Route element={<Confianza/>} path="/Servicios/Confianza" /> 
         <Route element={<Seguridadpor/>} path="/Seguridad/Portada" />
         <Route element={<Seguridadfocos/>} path="/Seguridad/Foco" /> 
         <Route element={<Seguridadestandare/>} path="/Seguridad/Estandar" />
         <Route element={<Seguridadprot/>} path="/Seguridad/Proteccion" />
         <Route element={<Seguridadreglas/>} path="/Seguridad/Regla" /> 
         <Route element={<Seguridadini/>} path="/Seguridad/Iniciativa" /> 
         <Route element={<SeguridadGaleria/>} path="/Seguridad/Galerias" /> 
         <Route element={<Sostenibilidadport/>} path="/Sostenibilidad/Portada" /> 
         <Route element={<Sostenibilidadinclu/>} path="/Sostenibilidad/Inclusion" /> 
         <Route element={<Sostenibilidadrel/>} path="/Sostenibilidad/Relacionamiento" /> 
         <Route element={<Sostenibilidadgal/>} path="/Sostenibilidad/Galeria" />
        </Routes>
        <Footer/>

 
    </BrowserRouter>

);
};

export default Layout;