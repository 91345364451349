import React from "react";
import Confianzacliente from "../../../components/servicios/confianza/confianza";
import { IoIosArrowForward } from "react-icons/io";
import { Container } from "@mui/material";
import { Helmet } from "react-helmet";



export default function Confianza() {

    return(
        <>
        <Helmet>
        <title>Axinntus | Quienes han confiado en nosotros.</title>
        <meta
          name="description"
          content="Axinntus ofrece soluciones industriales personalizadas para proyectos a corto plazo, adaptándose a las necesidades y estándares de los clientes con un servicio flexible y eficiente quienes han confiado en nosotros."
        />
        <meta
          name="keywords"
          content="Axinntus,  industriales, servicios personalizados, proyectos a corto plazo, necesidades del cliente, flexibilidad, estándares de seguridad, servicios a la medida, eficiencia."
        />
      </Helmet>
          <Container maxWidth="xl" sx={{marginTop:'-40px','@media (max-width: 960px)':{
                marginTop:'0px'
              },}} >
          <div
          className="paginacompleta"
   
  >
    <div
      style={{ display: "flex", alignItems: "center", fontSize: "0.8rem" }}
    >
      {" "}
      <p style={{ fontWeight: "400", color: "#898b8a" }}>
        {" "}
        Nuestros servicios
      </p>{" "}
      <IoIosArrowForward
        style={{
          marginLeft: "10px",
          marginRight: "10px",
          color: "#6f7170",
        }}
      />{" "}
      <strong style={{ color: "#6f7170" }}>
      Quienes han confiado
en nosotros</strong>
    </div>
  </div>
        </Container>
        <Confianzacliente/>
       
        </>
    )
}