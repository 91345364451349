
import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Grid, Container } from "@mui/material";


import "../../style/sostenibilidad/sostenibilidad.css";
import Sostenibilidadvideo from "./video";
import "../../style/sostenibilidad/videosos.css"

/* imagenes */
import hoja from "../../img/sostenibilidad/portada/hojas3.png";
import hoja1 from "../../img/sostenibilidad/portada/hojas4.png";
import descarga from "../../img/home/acreditacion/descarga.png";
import pdf1 from "../../img/pdf/politica-sostenibilidad.pdf";


export default function Sostenibilidadportada() {
  useEffect(() => {
    // Inicializa AOS con opciones de configuración si es necesario
    AOS.init({
      duration: 1000, // Duración de las animaciones en milisegundos
      once: true, // Solo realiza las animaciones una vez
    });
  }, []);
  return (
    <>
      <section className="contiene-portada-sostenibilidad">
        <img src={hoja} className="hoja" alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' />
        <Container maxWidth="md" sx={{ marginTop: '100px', marginBottom: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
          <Grid container className="contiene-sostenibilidad-portada" data-aos="fade-down">
            <div className="titulo-portada-sostenibilidad">
              <h1>Sostenibilidad en Axinntus</h1>
            </div>
            <div className="contieneportadaindososten">
              <div className="info-sostenibilidad-portada"> <span>Estamos comprometidos en crear valor y contribuir al desarrollo de los territorios donde operamos
                y al entorno que nos rodea. Nuestros compromisos de sostenibilidad están alineados con nuestra
                estrategia de negocio y requieren la colaboración de todas las áreas de nuestra organización.
              </span>
              </div>
              <div>
                <p className="infodos-sostenibilidad-portada"><strong>Nos esforzamos por garantizar una gestión sostenible en cada actividad que realizamos,</strong> 
                  conscientes del impacto que nuestras decisiones pueden tener en los grupos de interés.</p></div>

              <div className="borde-portada">
                <strong>Únete a nosotros en nuestro viaje hacia un futuro sostenible, donde combinamos valores de
                  negocio con la preservación del planeta.</strong>
              </div>

              <div className="button-descarga-sostenibilidad-portada" >
                
                <a href={pdf1} download target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: 'inherit'}}>
                    <button> <div className="icono-descargar"> <img src={descarga} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' /> </div> Descargar Política de Sostenibilidad</button>
                  </a>

              </div>
              </div>
          </Grid>
        </Container>
        <img src={hoja1} className="imagen-dos" alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' />
      </section>
      <Sostenibilidadvideo />
    </>
  );
}
