import Equiposaxinntus from "../../../components/servicios/equipo/equipos"
import { Helmet } from "react-helmet"



export default function Equipo() {

    return(
        <>
           <Helmet>
        <title>Axinntus |Nuestros equipos.</title>
        <meta
          name="description"
          content="Axinntus ofrece una gran variedad de activos en equipos móviles."
        />
        <meta
          name="keywords"
          content="Axinntus,  industriales, servicios, estándares de seguridad, servicios a la medida, activos en equipos móviles ."
        />
      </Helmet>
        <Equiposaxinntus/>
        </>
    )
}