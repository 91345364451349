
import { Container } from "@mui/material";
import "../../style/seguridad/foco.css";
import React, {  useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

/* imagenes */
import imagen from "../../img/seguridad/foco/4.png";

export default function Seguridadestandares() {
    useEffect(() => {
        // Inicializa AOS con opciones de configuración si es necesario
        AOS.init({
          duration: 1000, // Duración de las animaciones en milisegundos
          once: true, // Solo realiza las animaciones una vez
        });
      }, []);

return(
  <Container maxWidth="md" sx={{marginTop:'100px', marginBottom:'100px', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
    <section className="estandar" data-aos="fade-down">
        <h1>Estándares Riesgos Críticos 4 A+C</h1>
        <span>Los 4A+C reconocen 5 riesgos críticos que tienen un alto potencial de causar
accidentes en nuestras operaciones, busca convocar a todas las personas de
la compañía y reforzar la importancia del cuidado personal y grupal.</span>
    
    <img src={imagen} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' data-aos="zoom-in-left"/>
    
    </section>
    </Container>
)
}