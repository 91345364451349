
import React, { useState, useEffect, useCallback } from 'react';
import "../../style/nosotros/historia.css";
import { Grid, Container } from "@mui/material";
import Button from '@mui/material/Button';
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import pdf1 from "../../img/pdf/libro.pdf";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { styled } from '@mui/system';
import historia from "../../img/nosotros/historia/historia.png";
import descarga from "../../img/home/acreditacion/descarga.png"



// Estilo en línea para el componente


const StyledAccordion = styled(Accordion)(({ theme, error }) => ({
  border:'0px',
  fontSize:'0.8rem',
  fontFamily:'Compasse',
  boxShadow:'none',
  

  
  }));
  
const StyledTypography = styled(Typography)(({ theme, error }) => ({
  border:'0px',
  fontSize:'1.2rem',
  fontFamily:'Compasse',
  boxShadow:'none',
  fontWeight:'500',
  color: '#6f7170;',

  
  }));

  const lineStyle = {
    position: 'absolute',
    width: '100%',
    height: '2px',
    backgroundColor: '#898b8a',
    top: '50%',
    transform: 'translateY(-50%)',
  };
  
  const buttonContainerStyle = {
    position: 'absolute',
    top: '5%',
    transform: 'translateY(-50%)',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  };
  
  const buttonStyle = {
    position: 'relative',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    backgroundColor: '#ffff',
    border: '7px solid #898b8a',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#6fa967',
    fontSize: '12px',
  };
  
  const dateStyle = {
    position: 'absolute',
    top: '100%',
    transform: 'translateY(50%)',
    textAlign: 'center',
    width: '100%',
    
  };

  const MAX_VISIBLE_YEARS = 5;

  const getVisibleYears = (selectedYear, años) => {
    const currentIndex = años.indexOf(selectedYear);
    let startYear = currentIndex - Math.floor(MAX_VISIBLE_YEARS / 2);
    startYear = Math.max(0, startYear);
  
    return años.slice(startYear, startYear + MAX_VISIBLE_YEARS);
  };

export default function Nosotroshistoria() {
  const [selectedYear, setSelectedYear] = useState(null);
  const [años, setAños] = useState([ 2011, 2012, 2014, 2015, 2019, 2020, 2021,2022,2023,2024]);
  const [isLineVisible, setLineVisible] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);

  
  useEffect(() => {
    // Agregar un manejador de redimensión para controlar la visibilidad del menú móvil
    const handleResize = () => {
      if (window.innerWidth <= 599) {
        setMenuVisible(true);
      } else {
        setMenuVisible(false);
      }
    };
  
    // Adjuntar el manejador de redimensionamiento al evento de redimensionamiento del window
    window.addEventListener('resize', handleResize);
  
    // Limpieza del evento al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); 

   // Envolver showYear en useCallback
  const showYear = useCallback((year) => {
    const currentIndex = años.indexOf(year);
    setLineVisible(true);

    if (currentIndex !== 0) {
      const newAños = [
        ...años.slice(currentIndex),
        ...años.slice(0, currentIndex),
      ];

      setAños([...newAños]);

     
    }

    setSelectedYear(year);
  }, [años]);
  
    
    useEffect(() => {
      // Inicializa AOS con opciones de configuración si es necesario
      AOS.init({
        duration: 1000, // Duración de las animaciones en milisegundos
        once: true, // Solo realiza las animaciones una vez
      });
    }, []);
    
    useEffect(() => {
       
        showYear(2011);
      }, [ ]); 
      

    const getButtonStyle = (year) => {
        // Aplica un estilo diferente si el año está seleccionado
        return {
          ...buttonStyle,
          backgroundColor: selectedYear === year ? '#fff' : '#ffff',
          border: `7px solid ${selectedYear === year ? '#6fa967' : '#898b8a'}`,
          
        };
      };

    
      const goToPreviousYear = () => {
        // Retrocede al año 2011
        const yearToGoBack = 2011;
      
        // Actualiza el estado de los años con el año 2011 al frente
        setAños([yearToGoBack, ...años.filter(year => year !== yearToGoBack)]);
      
        // Muestra el año 2011
        showYear(yearToGoBack);
      };
      
      const goToNextYear = () => {
        const currentIndex = años.indexOf(selectedYear);
    
      
        if (currentIndex < años.length - 1) {
          // Avanza a un año siguiente
          const nextYear = años[currentIndex + 1];
          showYear(nextYear);
        }
      };
      
  
      const getInfoByYear = (year) => {
        // Devuelve información adicional dependiendo del año
        switch (year) {
          case 2011:
            return (
              <>
                <li>Inicio Axinntus Servicios Industriales, contrato Aes Central Angamos – Cochrane en Mejillones y Central Santa María de Colbún en Coronel.</li>
                <li> Traspaso contrato Planta Petroquim, comuna de Hualpén.</li>
              </>
            );
          case 2012:
            return (
              <>
                <li> Inicio contrato Central Bocamina, Octava Región.</li>
               
              </>
            );
          case 2014:
            return (
              <>
                <li>Inicio contrato CAP Valle de Huasco y contrato FEPASA en San Antonio.</li>
                
              </>
            );
          case 2015:
            return (
              <>
                <li>Inicio contrato Codelco Ventanas, Quinta Región.</li>
                <li> Traspaso a Axinntus contrato Central Guacolda, Huasco.</li>
                <li> Readjudicación contrato Aes, Central Angamos – Cochrane.</li>
              </>
            );
          case 2019:
            return (
              <>
                <li>Inicio contrato Fundición Altonorte, Segunda Región y contrato Engie, Complejo Mejillones.</li>
            
              </>
            );
            case 2020:
            return (
              <>
                <li>Inicio nuevo contrato Codelco División Ventanas, Quinta Región.</li>
            
              </>
            );
            case 2021:
              return (
                <>
                  <li>Readjudicación contrato Aes, Central Angamos – Cochrane.</li>
                  <li>Separación de Ultraport</li>
                </>
              );
              case 2022:
                return (
                  <>
                    <li>Apertura Casa Matriz en Antofagasta.</li>
                    <li>Readjudicación contrato Central Guacolda, Huasco.</li>
                  </>
                );
                case 2023:
                  return (
                    <>
                      <li>Readjudicación contrato Engie, Mejillones.</li>
                    </>
                  );
                  case 2024:
                    return (
                      <>
                        <li>Readjudicación contrato Colbún, VIII región.</li>
                        <li>Adjudicación contrato de limpieza industrial y aseo integral en Minera Sierra Gorda.</li>
                        <li>Adjudicación contrato mantenimiento sistemas de correas de transporte Engie, Mejillones.</li>
                      </>
                    );
                  default:
                    return <li>No hay información disponible para este año.</li>;
                }
              };
     
     
      
  return (
    <section className="contiene-historia">
      <Container maxWidth="md" sx={{marginTop:'50px', marginBottom:'100px', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
      <Grid container sx={{width:'100%', height:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
        <Grid item xs={12} sm={12} md={6} sx={{width:'100%', height:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
          <div className="titulo-historia" data-aos="fade-right">
           <div className='imagenhistorialogo'>
             <img src={historia} alt='mantenimiento'  title='mantenimiento' /></div>
            <h1  alt='mantenimiento' title='mantenimiento'>Nuestra historia y trayectoria</h1>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <div className="info-historia">
            <span  alt='mantenimiento industriales'  title='mantenimiento, industriales'>
              Axinntus nace al alero de Ultraport, cuando la operación en los
              puertos comienza a crecer afuera de ellos. Como la legislación y
              la naturaleza de los negocios portuarios y no portuarios son
              distintas, se planteó la <span></span>  
              <strong>
                necesidad de crear una compañía que estuviera enfocada al
                movimiento de carga tierra adentro.
              </strong>
            </span>
            <hr/>
            <span>
              Nuestras primeras oportunidades de negocio se generaron en el
              movimiento de graneles y, especialmente, del carbón, donde nos
              abrimos camino al ofrecer servicios de movimiento en las canchas
              de carbón en termoeléctricas, ampliando luego nuestra cartera para
              incluir limpieza industrial y mantenimiento de correas
              transportadoras, elementos fundamentales en el proceso de
              generación eléctrica.
            </span>
            <hr/>
            <span>
              <strong>
                Gracias a la dedicación y capacidad de nuestros equipos de
                trabajo, pudimos mirar hacia el futuro y expandirnos hacia
                industrias en las que nuestros servicios eran un valor agregado
                para mejorar la productividad, principalmente en la minería.
              </strong>
            </span>
            <hr/>
            <span>
              <strong>
                Al cumplir 10 años en el mercado en 2021, se trasladaron las
                oficinas centrales desde Valparaíso a la ciudad de Antofagasta,
                de manera de llevar la administración del negocio más cerca de
                las operaciones, pero apostando también a fortalecer la
                presencia en regiones, la formación de mano de obra local,
                promover la descentralización y generar un vínculo de largo
                plazo con las comunidades en las cuales nos insertamos.
              </strong>
            </span>
          </div>
        </Grid>
      </Grid>
      </Container>
     <section className="libro-nosotros">
     <Container maxWidth="md" sx={{marginTop:'100px', marginBottom:'100px', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
        <div className="info-libro">
            <h1>Si quieres saber más de nuestra historia y trayectoria,
te invitamos a leer nuestro:</h1>
        </div>
        <div className="contiene-descarga">
           <div className="titulolibrohistoria"> <h1>Libro</h1><h2>10  años</h2></div>
          
           <a href={pdf1} download target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: 'inherit'}}>
           <Button  className="boton-descarga" variant="outlined"> <div className="icono-descargar"> <img src={descarga} alt='mantenimiento' title='mantenimiento'/> </div> Descargar</Button>
        </a>
       
        </div>
        </Container>
     </section>
     <Container maxWidth="md" sx={{marginTop:'100px', marginBottom:'100px', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
     {menuVisible ? (
        <section >
        <div>
        <StyledAccordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <StyledTypography>2011</StyledTypography>
          </AccordionSummary>
          <AccordionDetails className='acordeon'  sx={{boxShadow:"0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);"}}>
            <ul className='descripcionfecha'>
            <li>Inicio Axinntus Servicios Industriales, contrato Aes Central Angamos – Cochrane en Mejillones y Central Santa María de Colbún en Coronel.</li>
                <li> Traspaso contrato Planta Petroquim, comuna de Huapén.</li>
                </ul>
           
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <StyledTypography>2012</StyledTypography>
          </AccordionSummary>
          <AccordionDetails className='acordeon'  sx={{boxShadow:"0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);"}}>
           
              <ul className='descripcionfecha'>
            <li> Inicio contrato Central Bocamina, Octava Región</li></ul>
           
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <StyledTypography>2014</StyledTypography>
          </AccordionSummary>
          <AccordionDetails className='acordeon'  sx={{boxShadow:"0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);"}}>
           
              <ul className='descripcionfecha'>
              <li>Inicio contrato CAP Valle de Huasco y contrato FEPASA en San Antonio</li>
              </ul>
           
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <StyledTypography>2015</StyledTypography>
          </AccordionSummary>
          <AccordionDetails className='acordeon'  sx={{boxShadow:"0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);"}}>
           
             <ul className='descripcionfecha'>
             <li>Inicio contrato Codelco Ventanas, Quinta Región</li>
                <li> Traspaso a Axinntus contrato Central Guacolda, Huasco</li>
                <li> Readjudicación contrato Aes, Central Angamos – Cochrane</li>
             </ul>
            
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <StyledTypography>2019</StyledTypography>
          </AccordionSummary>
          <AccordionDetails className='acordeon'  sx={{boxShadow:"0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);"}}>
           
              <ul className='descripcionfecha'>
              <li>Inicio contrato Fundición Altonorte, Segunda Región y contrato Engie, Complejo Mejillones</li>
              </ul>
           
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <StyledTypography>2020</StyledTypography>
          </AccordionSummary>
          <AccordionDetails className='acordeon'  sx={{boxShadow:"0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);"}}>
           
            <ul className='descripcionfecha'>
            <li>Inicio nuevo contrato Codelco División Ventanas, Quinta Región</li>
            </ul>
           
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <StyledTypography>2021</StyledTypography>
          </AccordionSummary>
          <AccordionDetails className='acordeon'  sx={{boxShadow:"0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);"}}>
           
             <ul className='descripcionfecha'>
             <li> Readjudicación contrato Aes, Central Angamos – Cochrane</li>
                  <li>Separación de Ultraport</li>
             </ul>
           
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <StyledTypography>2022</StyledTypography>
          </AccordionSummary>
          <AccordionDetails className='acordeon'  sx={{boxShadow:"0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);"}}>
            
             <ul className='descripcionfecha'>
             <li>Apertura Casa Matriz en Antofagasta</li>
                    <li>Readjudicación contrato Central Guacolda, Huasco</li>
             </ul>
           
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <StyledTypography>2023</StyledTypography>
          </AccordionSummary>
          <AccordionDetails className='acordeon' sx={{boxShadow:"0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);"}}>
          
            <ul className='descripcionfecha'>  <li>Readjudicación contrato Engie, Mejillones</li></ul>
           
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <StyledTypography>2024</StyledTypography>
          </AccordionSummary>
          <AccordionDetails className='acordeon'sx={{boxShadow:"0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);"}}>
          
            <ul className='descripcionfecha'>  <li>Readjudicación contrato Colbún, VIII región.</li>
            <li>Adjudicación contrato de limpieza industrial y aseo integral en Minera Sierra Gorda.</li>
            <li>Adjudicación contrato mantenimiento sistemas de correas de transporte Engie, Mejillones.</li>
            </ul>
          
          </AccordionDetails>
        </StyledAccordion>
      </div>
        </section>
      ) : (
        // Renderizar el primer componente aquí cuando el ancho de la ventana es mayor a 599
        <section >
          <section className="linea">
      <div className="contiene-linea-tiempo">
        <div  className="timelineStyle" >
          <div style={lineStyle}></div>
          <div style={buttonContainerStyle}>
          {getVisibleYears(selectedYear, años).map((year) => (
              <div key={year} style={getButtonStyle(year)} onClick={() => showYear(year)}>
                <div className="titulo-fecha" style={dateStyle}>
                  {year}
                </div>
              </div>
            ))}
          </div>
          {selectedYear && (
            <div
              className={`linea-mostradora ${isLineVisible ? 'mostrar' : ''}`}
              style={{
                position: 'absolute',
                width: '2px',
                top: '-120px',
                marginTop: '0',
                bottom: '0',
                left: '0',
                marginLeft: '15px',
                borderLeft: '2px dashed #898b8a',
                boxSizing: 'border-box',
                marginBottom:'18px'
              }}
            ></div>
          )}
        </div>
        <div className="informacion">
          <h2>{selectedYear}</h2>
          <ul>{getInfoByYear(selectedYear)}</ul>
        </div>
      </div>
      
    </section>
    <section>{selectedYear && (
        <div className='flechas-lineas' style={{
          position: 'relative',
          top: '185px',
          display: 'flex',
          justifyContent: 'flex-end',
          marginRight: '2%',
          marginBottom:'141px'
        }}>
          <Button onClick={goToPreviousYear}><IoIosArrowBack  className='flecha-calendario' /></Button>
          <Button onClick={goToNextYear}><IoIosArrowForward  className='flecha-calendario' /></Button>
        </div>
      )} </section>
         
        </section>
      )}
      </Container>
     

      
   
    </section>
  );
}
