
import React, {  useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

import { Grid, Container } from "@mui/material";
import "../../style/seguridad/iniciatividad.css";

/* imagenes */
import imagen from "../../img/seguridad/iniciatividad/huella-carbono.png";
import imagen1 from "../../img/seguridad/iniciatividad/eficiente.png";
import imagen2 from "../../img/seguridad/iniciatividad/cuantificacion.png";
import imagen3 from "../../img/seguridad/iniciatividad/electro-movilidad.png";
import imagen4 from "../../img/seguridad/iniciatividad/medio-ambientales.png";
import imagen5 from "../../img/seguridad/iniciatividad/gases-refrigerantes.png";
import imagen6 from "../../img/seguridad/iniciatividad/consumo-agua.png";
import imagen7 from "../../img/seguridad/iniciatividad/gestion-residuos.png";

export default function Seguridadiniciativa() {
  useEffect(() => {
    // Inicializa AOS con opciones de configuración si es necesario
    AOS.init({
      duration: 1000, // Duración de las animaciones en milisegundos
      once: true, // Solo realiza las animaciones una vez
    });
  }, []);
  return (
    <Container maxWidth="md" sx={{marginTop:'50px', marginBottom:'100px'}}>
    <section className="iniciativapag" data-aos="fade-up">
      <section className="titulo-iniciativa">
        <h1>Iniciativas y proyectos de medioambiente</h1>
      </section>

      <section className="contiene-imagenes-iniciativa">
        <Grid container className="contieneinicitividad">
          <Grid item xs={12} sm={12} md={3}>
            <div className="card-iniciativa">
              <img src={imagen} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' />
              <h1>Medición Huella de Carbono Alcance 1-2-3</h1>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={1} className="separador">
            <div></div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="card-iniciativa">
              <img src={imagen1} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' />
              <h1>Conducción</h1> <h1>Eficiente</h1>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={1} className="separador">
            <div></div>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <div className="card-iniciativa">
              <img src={imagen2} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' />
              <h1>Compromiso de Cuantificación</h1>
            </div>
          </Grid>
        </Grid>
        <Grid container className="contieneinicitividad">
          <Grid item xs={12} sm={12} md={3}>
            <div className="card-iniciativa">
              <img src={imagen3} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' />
              <h1>Proyecto electro movilidad en camionetas</h1>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={1} className="separador">
            <div></div>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <div className="card-iniciativa">
              <img src={imagen4} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' />
              <h1>Concurso Ideas Medioambientales</h1>
            </div>
          </Grid>

          <Grid  item xs={12} sm={12} md={1} className="separador">
            <div></div>
          </Grid>
          <Grid  item xs={12} sm={12} md={3}>
            <div className="card-iniciativa">
              <img src={imagen5} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' />
              <h1>Recuperación de Gases Refrigerantes</h1>
            </div>
          </Grid>
        </Grid>
        <Grid container className="contienesinicitividad">
          <Grid item xs={12} sm={12} md={5}>
            <div className="cardiniciativa">
              <img src={imagen6} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' />
              <h1>Medición </h1>
              <h1>consumo de agua</h1>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={1} className="separador">
            <div></div>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <div className="card-iniciativa">
              <img src={imagen7} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' className="grande" />
              <h1>Gestión de</h1>
              <h1>Residuos</h1>
            </div>
          </Grid>
        </Grid>
      </section>
    </section>
    </Container>
  );
}
