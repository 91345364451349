
import "../../../style/servicios/equipo/equipos.css";
import React, { useEffect, useState } from "react";
import { Grid, Container } from "@mui/material";
import { IoIosArrowForward } from "react-icons/io";
import { useInView } from 'react-intersection-observer';
import AOS from 'aos';
import 'aos/dist/aos.css';

/* imagenes */
import flecha from "../../../img/servicios/equipo/flecha-amarilla.png";
import imagen from "../../../img/servicios/equipo/equipos-cargadores-frontales.png";
import imagen1 from "../../../img/servicios/equipo/equipos-gruas-horquillas.png";
import imagen2 from "../../../img/servicios/equipo/equipos-semi-remolque.png";
import imagen3 from "../../../img/servicios/equipo/equipos-tracto-camion.png";
import imagen4 from "../../../img/servicios/equipo/equipos-bulldozers.png";
import imagen5 from "../../../img/servicios/equipo/equipos-camion-aljibe.png";
import imagen6 from "../../../img/servicios/equipo/equipos-camion-tolva.png";
import imagen7 from "../../../img/servicios/equipo/equipos-camion-aspirador.png";
import imagen8 from "../../../img/servicios/equipo/equipos-barredoras.png";

import imagen9 from "../../../img/servicios/equipo/equipos-camion-combustible.png";
import imagen10 from "../../../img/servicios/equipo/camion-multiproposito.png";
import imagen11 from "../../../img/servicios/equipo/equipos-rodillo.png";
import imagen12 from "../../../img/servicios/equipo/equipos-apilador.png";
import imagen13 from "../../../img/servicios/equipo/equipos-retroexcavadora.png"

const CountingComponent = ({ isVisible, targetCount, step, interval }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let intervalId;
    if (isVisible) {
      intervalId = setInterval(() => {
        setCount(prevCount => Math.min(prevCount + step, targetCount));
      }, interval);
    }

    return () => clearInterval(intervalId);
  }, [isVisible, targetCount, step, interval]);

  return <span>{count}</span>;
};

export default function Equiposaxinntus() {

  const [menuVisible, setMenuVisible] = useState(false);

 

  useEffect(() => {
    // Agregar un manejador de redimensión para controlar la visibilidad del menú móvil
    const handleResize = () => {
      if (window.innerWidth <= 700) {
        setMenuVisible(true);
      } else {
        setMenuVisible(false);
      }
    };

    // Adjuntar el manejador de redimensionamiento al evento de redimensionamiento del window
    window.addEventListener('resize', handleResize);

    // Limpieza del evento al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Inicializa AOS con opciones de configuración si es necesario
    AOS.init({
      duration: 1000, // Duración de las animaciones en milisegundos
      once: true, // Solo realiza las animaciones una vez
    });
  }, []);

  const [ref1] = useInView({
    triggerOnce: true,
    threshold: 1,
  });

  const [ref2, inView2] = useInView({
    triggerOnce: true,
    threshold: 0,  // Establecer el umbral a 0 para que se active cuando aparezca en la pantalla
  });

  const Data = [
    { id: 1, imagen: imagen, titulo: "Cargadores Frontales", count: 20 },
    { id: 2, imagen: imagen1, titulo: "Grúas horquillas", count: 18 },
    { id: 3, imagen: imagen2, titulo: "Semi Remolque", count: 13 },
    { id: 4, imagen: imagen3, titulo: "Tracto camión", count: 13 },
    { id: 5, imagen: imagen4, titulo: "Bulldozers", count: 10 },
    { id: 6, imagen: imagen5, titulo: "Camión Aljibe", count: 9 },
    { id: 7, imagen: imagen7, titulo: "Camión aspirador", count: 7 },
    { id: 8, imagen: imagen8, titulo: "Barredoras", count: 6 },
    { id: 9, imagen: imagen6, titulo: "Camión Tolva", count: 5 },
    { id: 10, imagen: imagen9, titulo: "Camión combustible", count: 3 },
    { id: 11, imagen: imagen13, titulo: "Retroextracavadora", count: 3 },
    { id: 12, imagen: imagen11, titulo: "Rodillo", count: 2 },
  ];

  const Datas = [
    { id: 1, imagen: imagen10, titulo: "camión multipropósito", count: 1 },
    { id: 2, imagen: imagen12, titulo: "Apilador", count: 1 },
  ];

  return (
    <section style={{ background: "#2c3543" }} ref={ref1}>
      <Container maxWidth="xl" sx={{ marginTop: '-0px' }}>
        <div>
          <div className="paginascompleta" style={{ display: "flex", alignItems: "center", fontSize: "0.8rem", position: "absolute" }}>
            <p style={{ fontWeight: "400", color: "#898b8a" }}>Nuestros servicios</p>
            <IoIosArrowForward style={{ marginLeft: "10px", marginRight: "10px", color: "#6f7170" }} />
            <strong style={{ color: "#6f7170" }}>Nuestros Equipos</strong>
          </div>
        </div>
      </Container>
      <Container maxWidth="md" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
        <section className="todalaseccion" style={{ paddingBottom: "150px" }} ref={ref1}>
          <section data-aos="fade-right">
            <section className="titulo">
              <h1 alt="axinntus servicios equipos activos" title='axinntus servicios equipos activos'>Nuestros Equipos</h1>
            </section>
            <section className="contiene-todo">
              <div className="numeracion">
                <strong>
                  <h1>112</h1>
                </strong>
                <h1 className="nombre">Equipos</h1>
              </div>
              <div className="flecha">
                <img src={flecha} alt="axinntus servicios equipos activos" title='axinntus servicios equipos activos' />
              </div>
              <div className="segundainfo">
                <strong>
                  <h1 alt="axinntus servicios equipos activos" title='axinntus servicios equipos activos'>+USD 10 Millones</h1>
                </strong>
                <h1 className="nombre">de activos en equipos móviles</h1>
              </div>
            </section>
          </section>
          <section>
            {menuVisible ? (
              <>
                <section className="imagenesequipos">
                  <Grid container>
                    {Data.map((item) => (
                      <Grid
                      item
                        key={item.id}
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column"
                        }}
                      >
                        <div className="contienetodo">
                          <div className="contieneimgnumero">
                            <div className="imagenmovilequipo">
                              <img src={item.imagen} alt="axinntus servicios equipos activos" title='axinntus servicios equipos activos' />
                            </div>
                            <h2>{item.count}</h2>
                          </div>
                          <h2 className="titulo">{item.titulo}</h2>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                  <Grid container>
                    {Datas.map((item) => (
                      <Grid
                      item
                        key={item.id}
                        xs={12}
                        sm={6}
                        md={6}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column"
                        }}
                      >
                        <div className="contienetodo">
                          <div className="contieneimgnumero">
                            <div className="imagenmoviledosquipo">
                              <img src={item.imagen} alt="axinntus servicios equipos activos" title='axinntus servicios equipos activos' />
                            </div>
                            <h2>{item.count}</h2>
                          </div>
                          <h2 className="titulo">{item.titulo}</h2>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </section>
              </>
            ) : (
              <>
                <section className="imagenesequipos" ref={ref2}>
                  <Grid container>
                    {Data.map((item) => (
                      <Grid
                      item
                        key={item.id}
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column"
                        }}
                      >
                        <div className="contienetodo">
                          <div className="contieneimgnumero">
                            <div className="imagenmovilequipo">
                              <img src={item.imagen} alt="axinntus servicios equipos activos" title='axinntus servicios equipos activos' />
                            </div>
                            <h2>
                              <CountingComponent
                                isVisible={inView2}
                                targetCount={item.count}
                                step={Math.ceil(item.count / 100)}
                                interval={100}
                              />
                            </h2>
                          </div>
                          <h2 className="titulo">{item.titulo}</h2>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </section>
                <section className="imagenesequipos" ref={ref2}>
                  <Grid container>
                    {Datas.map((item) => (
                      <Grid
                      item
                        key={item.id}
                        xs={12}
                        sm={6}
                        md={6}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column"
                        }}
                      >
                        <div className="contienetodo">
                          <div className="contieneimgnumero">
                            <div className="imagenmoviledosquipo">
                              <img src={item.imagen} alt="axinntus servicios equipos activos" title='axinntus servicios equipos activos' />
                            </div>
                            <h2>
                              <CountingComponent
                                isVisible={inView2}
                                targetCount={item.count}
                                step={Math.ceil(item.count / 100)}
                                interval={100}
                              />
                            </h2>
                          </div>
                          <h2 className="titulo">{item.titulo}</h2>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </section>
              </>
            )}
          </section>
        </section>
      </Container>
    </section>
  );
}