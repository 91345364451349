import React from 'react';
import { useState, useEffect, useCallback  } from 'react';
import { IoClose } from "react-icons/io5";
import { Button,  MenuItem, Box, Accordion, AccordionSummary, AccordionDetails, Typography, List, Divider,  Modal} from '@mui/material';
import { FaInstagram, FaLinkedinIn, FaFacebookF, FaYoutube } from 'react-icons/fa';
import { styled } from '@mui/system';
import { Formulario } from '../formulario/formulario';
import NavBarWeb from './NavBarWeb';
import NavBarMovil from './NavBarMovil';
import logo from "../../img/navbar/logo.png"
import "../../style/navbar/navbar.css"

export const Navbar = () => {
  const [visible, setVisible] = useState(true);
  const [menuVisible, setMenuVisible] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [state, setState] = useState({ right: false });
  const [anchorEl, setAnchorEl] = useState(null);

  // Maneja la visibilidad de la navbar en función del scroll
  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setVisible(true); // Oculta la navbar al hacer scroll hacia abajo
      } else {
        setVisible(true); // Muestra la navbar al hacer scroll hacia arriba
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setMenuVisible(window.innerWidth > 960);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleDrawer = useCallback((anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  }, [state]);

  const openPopup = useCallback(() => setPopupVisible(true), []);
  const closePopup = useCallback(() => setPopupVisible(false), []);
  const handleClick = useCallback((event) => setAnchorEl(event.currentTarget), []);
  const handleClose = useCallback(() => setAnchorEl(null), []);

  const list = useCallback((anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 280,
        marginTop: "80px",
        '@media (max-width: 350px)': {
          width: anchor === "top" || anchor === "bottom" ? "auto" : 220,
        },
      }}
      role="presentation"
      className="listado-menu"
    >
      <List>
        {[
          {
            label: "Sobre Nosotros",
            links: [
              { href: "/Nosotros/Portada", label: "Quienes Somos" },
              { href: "/Nosotros/Propositos", label: "Propósito, misión, visión y valores" },
              { href: "/Nosotros/Historia", label: "Nuestra historia y trayectoria" },
              { href: "/Nosotros/Personas", label: "Nuestras personas" },
              { href: "/Nosotros/Mapa", label: "Operaciones y contratos" },
            ]
          },
          {
            label: "Nuestros servicios",
            links: [
              { href: "/Servicios/ManejoSolidos", label: "Manejo de Graneles Sólidos" },
              { href: "/Servicios/Logistica", label: "Logística interna y transferencia de material" },
              { href: "/Servicios/Limpiezaindustrial", label: "Limpieza industrial" },
              { href: "/Servicios/Limpieza", label: "Limpieza de Superficies y Estructuras industriales con dron" },
              { href: "/Servicios/Mantenimiento", label: "Mantenimiento industrial" },
              { href: "/Servicios/Soluciones", label: "Otras soluciones industriales a la medida" },
              { href: "/Servicios/Equipo", label: "Nuestros equipos" },
              { href: "/Servicios/Confianza", label: "Nuestros clientes" },
            ]
          },
          {
            label: "Seguridad, Salud Ocupacional, Medioambiente y Calidad",
            links: [
              { href: "/Seguridad/Portada", label: "Política de Seguridad" },
              { href: "/Seguridad/Foco", label: "Focos de seguridad y socioambientales de Ultramar" },
              { href: "/Seguridad/Estandar", label: "Estándares Riesgos Críticos 4 A+C" },
              { href: "/Seguridad/Proteccion", label: "Estándares de Protección Ambiental" },
              { href: "/Seguridad/Regla", label: "Reglas de Oro para el Cuidado de la Vida" },
              { href: "/Seguridad/Iniciativa", label: "Iniciativas y proyectos de medioambiente" },
              { href: "/Seguridad/Galerias", label: "Galería de fotos actividades más destacadas" },
            ]
          },
          {
            label: "Sostenibilidad",
            links: [
              { href: "/Sostenibilidad/Portada", label: "Pilares de Sostenibilidad" },
              { href: "/Sostenibilidad/Inclusion", label: "Inclusión y Diversidad" },
              { href: "/Sostenibilidad/Relacionamiento", label: "Programas Comunitarios" },
              { href: "/Sostenibilidad/Galeria", label: "Galería de fotos actividades más destacadas" },
            ]
          },
          {
            label: 'Canal de consultas y denuncias',
            linkLabel: 'https://www.eticaaxinntus.cl',
            links: [] // No tiene enlaces secundarios, solo el principal.
          },
          {
            label: 'Únete a nuestro equipo',
            linkLabel: '/Nuestroequipo',
            links: [] // No tiene enlaces secundarios, solo el principal.
          }
        ].map((menu, index) => (
          menu.links && menu.links.length > 0 ? (
            <Accordion
              key={`menu-${index}`}
              className="accordion"
            >
              <AccordionSummary
                aria-controls={`panel-${index}-content`}
                id={`panel-${index}-header`}
              >
                <StyledTypography sx={{ width: "100%", flexShrink: 0 }}>
                  {menu.label}
                </StyledTypography>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  {menu.links.map((link, idx) => (
                    <a key={`link-${idx}`} href={link.href} onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
                      <StyledMenuItem>
                        {link.label}
                      </StyledMenuItem>
                    </a>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          ) : (
            <AccordionSummary
              key={`menu-${index}`}
              aria-controls={`panel-${index}-content`}
              id={`panel-${index}-header`}
            >
              <a href={menu.linkLabel} target="_blank" rel="noopener noreferrer">
                <StyledTypography sx={{ width: "100%", flexShrink: 0 }}>
                  {menu.label}
                </StyledTypography>
              </a>
            </AccordionSummary>
          )
        ))}
        <Divider style={{ marginTop: '10px', marginBottom: '0px' }} />
        <div className="contenedor-redes-sociales-superior">
          {[
            { href: "https://www.instagram.com/axinntus/", icon: <FaInstagram className="iconos-contacto" /> },
            { href: "https://www.linkedin.com/company/axinntus?originalSubdomain=cl", icon: <FaLinkedinIn className="iconos-contacto" /> },
            { href: "https://www.facebook.com/Axinntus/?locale=es_LA", icon: <FaFacebookF className="iconos-contacto" /> },
            { href: "https://www.youtube.com/@axinntus7615", icon: <FaYoutube className="iconos-contacto" /> }
          ].map((social, index) => (
            <button key={`social-${index}`} className="contacto-icono-redes" onKeyDown={toggleDrawer(anchor, false)}>
              <a href={social.href} target="_blank" rel="noopener noreferrer">{social.icon}</a>
            </button>
          ))}
        </div>
        <div style={{ marginTop: '30px', padding: '0px 10px' }} onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
          <StyledButtoncotizar className="boton-cotizar" onClick={openPopup}>Solicitar más información</StyledButtoncotizar>
        </div>
      </List>
    </Box>
  ), [toggleDrawer, openPopup]);

  return (
    <section className={visible ? 'navbar' : 'hidden'}>
      {menuVisible ? (
        <NavBarWeb
          logo={logo}
          handleClick={handleClick}
          handleClose={handleClose}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          openPopup={openPopup}
        />
      ) : (
        <NavBarMovil
          logo={logo}
          state={state}
          toggleDrawer={toggleDrawer}
          popupVisible={popupVisible}
          closePopup={closePopup}
          list={list}
        />
      )}
      <Modal
        open={popupVisible}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 800,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            outline: 0,
            '@media (max-width: 900px)': {
              width: '90%',
              p: 1,
            },
          }}
        >
          <button
            className='buttoncloseform'
            onClick={closePopup}
          >
            <IoClose />
          </button>
          <Formulario />
        </Box>
      </Modal>
    </section>
  );
};



const StyledTypography  = styled(Typography )(({ theme, error }) => ({
  padding:'15px 0px',
  fontSize:'0.9rem',
  color: '#55565a',
  textAlign:'flex-end',
  fontFamily: 'Compasse',
  background:'trasparent',
  textTransform:'none',
  lineHeight:'1.2',
  fontWeight:'400',
  '@media (max-width: 1340px)':{
    padding:'15px 10px',
  },
  '@media (max-width: 1300px)':{
    fontSize:'0.8rem',
  },
  '@media (max-width: 1090px)':{
    fontSize:'0.9rem',
    padding:'10px 0px',
  },
}));

const StyledButtoncotizar = styled(Button)(({ theme, error }) => ({
  padding:'12px 0px',
  width:'100%',
  border:'0px',
  borderRadius:'50px',
  fontSize:'0.9rem',
  color: '#fff',
  background:'#6fa967',
  textTransform:'none',
  lineHeight:'1.2',
  fontWeight:'400',
  '@media (max-width: 1300px)':{
    fontSize:'0.8rem',
  },
  '@media (max-width: 1090px)':{
    fontSize:'0.6rem',
    padding:'12px 0px',
  },
}));

 

const StyledMenuItem = styled(MenuItem)(({ theme, error }) => ({
    fontSize: '0.7rem',
    marginLeft:'-20px',
    paddingRight:'50px',
    textTransform: 'none',
    color: '#55565a',
    fontFamily:'Compasse',
}))