
import React, {  useEffect } from 'react';
import "../../style/nosotros/proposito.css";
import { Grid, Container } from "@mui/material";
import AOS from 'aos';
import 'aos/dist/aos.css';
/* imgnes */
import logo from "../../img/nosotros/proposito/seccion.png";
import equipo from "../../img/nosotros/proposito/equipo.jpg";
import rosa from "../../img/nosotros/proposito/proposito.png";
import seguridad from "../../img/nosotros/proposito/seguridad.png";
import integridad from "../../img/nosotros/proposito/integridad.png";
import excelencia from "../../img/nosotros/proposito/excelencia.png";
import pasion from "../../img/nosotros/proposito/pasion.png";
import vision from "../../img/nosotros/proposito/vision.jpg";
import mision from "../../img/nosotros/proposito/mision.jpg";

export default function Nosotrosproposito() {
  useEffect(() => {
    // Inicializa AOS con opciones de configuración si es necesario
    AOS.init({
      duration: 1000, // Duración de las animaciones en milisegundos
      once: true, // Solo realiza las animaciones una vez
    });
  }, []); 

  const valoresData = [
    {
      imgSrc: seguridad,
      imgAlt: 'seguridad industriales',
      imgTitle: 'seguridad industriales',
      title: 'Seguridad',
      description: 'Cuidamos la vida de nuestros colaboradores y la salud de las comunidades donde operamos, promoviendo lugares de trabajo libres de riesgo y amigables con el medio ambiente.',
    },
    {
      imgSrc: integridad,
      imgAlt: 'personas',
      imgTitle: 'personas',
      title: 'Integridad',
      description: 'Actuamos con altos estándares éticos que garantizan la sustentabilidad y resguardan nuestra reputación, teniendo a nuestras personas al centro de lo que hacemos.',
    },
    {
      imgSrc: pasion,
      imgAlt: 'operación',
      imgTitle: 'operación',
      title: 'Pasión',
      description: 'Sentimos pasión por nuestra actividad, por conocer y entender a nuestros clientes, ocupándonos de los detalles que marcan la diferencia en cada una de nuestras operaciones.',
    },
    {
      imgSrc: excelencia,
      imgAlt: 'servicios',
      imgTitle: 'servicios',
      title: 'Excelencia',
      description: 'Aportamos al desarrollo y la competitividad de nuestros clientes anticipándonos a sus necesidades, con un servicio a la medida e innovador, creando soluciones más allá de lo tradicional.',
    }
  ];
  
  return (
    <section >
       <Container maxWidth="md" sx={{marginTop:'100px', marginBottom:'50px', display:'flex', alignItems:'center', justifyContent:'center'}}>
      <div className="titulo-proposito" data-aos="fade-down">
        <img src={logo} alt='seguridad industriales  y multiculturalidad de equipos personas' title='seguridad industriales  y multiculturalidad de equipos personas'/>
        <div className="todotitulo">
        <h1 alt='seguridad industriales  y multiculturalidad de equipos personas'  title='seguridad industriales  y multiculturalidad de equipos personas'>Propósito, </h1>
        <h1 alt='seguridad industriales  y multiculturalidad de equipos personas'  title='seguridad industriales  y multiculturalidad de equipos personas'>misión, visión</h1>
        <h1 alt='seguridad industriales  y multiculturalidad de equipos personas'  title='seguridad industriales  y multiculturalidad de equipos personas'>y valores</h1></div>
      </div>
</Container>
<Container maxWidth="xl" sx={{   ' @media (max-width: 1600px)': {
            paddingLeft:'0px'
          }}}>
      <Grid container>
        <Grid item xs={12} sm={6} sx={{width:'100%', height:'100%'
        }}>
          <div className="contiene-img-proposito">
            <img src={equipo} alt='seguridad industriales  y multiculturalidad de equipos personas' title='seguridad industriales  y multiculturalidad de equipos personas' />
          </div>
        </Grid>
        <Grid  item xs={12} sm={6}>
          <div className="contiene-info-proposito">
            <img src={rosa} alt='seguridad industriales  y multiculturalidad de equipos personas' title='seguridad industriales  y multiculturalidad de equipos personas' />
            <span alt='seguridad industriales  y multiculturalidad de equipos personas'  title='seguridad industriales  y multiculturalidad de equipos personas'>
              Nuestro propósito es{" "}
              <strong>
                desarrollar soluciones industriales seguras e innovadoras para
                nuestros clientes,
              </strong>{" "}
              cuidando a las personas y su entorno para un futuro mejor.
            </span>
          </div>
        </Grid>
      </Grid>
      </Container>
      <Container maxWidth="md" sx={{marginTop:'100px', marginBottom:'100px', display:'flex', alignItems:'center', justifyContent:'center'}}> 
      <Grid container className="contiene-mv">
        <Grid item xs={12} sm={6} className="misionvisioncontiene" style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
          <div className="contiene-mision">
           <img src={mision} alt='seguridad industriales  y multiculturalidad de equipos personas' title='seguridad industriales  y multiculturalidad de equipos personas' />
          </div>
        </Grid>
        <Grid  item xs={12} sm={6} className="misionvisioncontiene" style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
          <div className="contiene-mision">
          <img src={vision} alt='seguridad industriales  y multiculturalidad de equipos personas' title='seguridad industriales  y multiculturalidad de equipos personas' />
          </div>
        </Grid>
      </Grid>
      </Container>

      <Container maxWidth="md" sx={{ marginTop: '100px', marginBottom: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
        <div className="contiene-titulo-valores">
          <h1>Nuestros Valores</h1>
        </div>

        <Grid container className="valor-contiene" data-aos="fade-up">
          {valoresData.map((valor, index) => (
            <Grid key={index} item xs={12} sm={6} md={3}>
              <div className="card-valores">
                <img src={valor.imgSrc} alt={valor.imgAlt}  title={valor.imgTitle} />
                <h1>{valor.title}</h1>
                <span>{valor.description}</span>
              </div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </section>
  );
}
