
import React, { useState, useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import { Grid, Container, Modal, Box } from "@mui/material";
import { Formulario } from "../../formulario/formulario";
import "../../../style/servicios/manejo/manejo.css";


/* imagenes */
import pdf from "../../../img/pdf/BROCHURE-AXNN-2024.pdf";
import imagen from "../../../img/servicios/limpiezadron/5.png"
import descargar from "../../../img/servicios/otros/boton-descargar.png"

export default function Limpiezadron() {

    useEffect(() => {
        // Inicializa AOS con opciones de configuración si es necesario
        AOS.init({
            duration: 1000, // Duración de las animaciones en milisegundos
            once: true, // Solo realiza las animaciones una vez
        });
    }, []);

    const [popupVisible, setPopupVisible] = useState(false);
    const openPopup = () => setPopupVisible(true);
    const closePopup = () => setPopupVisible(false);
    return (
        <>
            <Container maxWidth="md" sx={{ marginTop: '50px', marginBottom: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>

                <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: "0px" }} data-aos="fade-down">
                    <Grid item xs={12} sm={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "0px" }}>
                        <div className="contiene-imagen">
                            <img src={imagen} alt="axinntus servicios, industriales, mantenimiento, operación, activos"  title='axinntus servicios, industriales, mantenimiento, operación, activos' />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={8} sx={{
                        width: '100%', paddingRight: '40px', '@media (max-width: 600px)': {
                            padding: '0px', paddingLeft: '0px', paddingRight: '0px',
                        },
                    }}>
                        <div className="contiene-informacion-manejo">
                            <h1 alt="axinntus servicios, industriales, mantenimiento, operación, activos" title='axinntus servicios, industriales, mantenimiento, operación, activos'>Limpieza de Superficies y</h1>
                            <h1 alt="axinntus servicios, industriales, mantenimiento, operación, activos" title='axinntus servicios, industriales, mantenimiento, operación, activos'>Estructuras industriales con dron</h1>
                            <div className="descripcion">
                                <span>
                                    Axinntus en conjunto con KTV Working Drone,
                                    empresa Noruega con 30 años de experiencia en
                                    limpieza de fachadas, ofrecen un servicio que
                                    se puede adaptar a cualquier lugar y estructura,
                                    brindando una operación segura y con altos
                                    estándares de excelencia operacional.
                                </span>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>

            <section className="productos">
                <Container maxWidth="md" sx={{ marginTop: '50px', marginBottom: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <div className="titulo-productos">
                        <h2 alt="axinntus servicios, industriales, mantenimiento, operación, activos" title='axinntus servicios, industriales, mantenimiento, operación, activos'>Algunos de los servicios que realizamos son:</h2>
                    </div>

                    <div className="lista">

                        <div className="uno">
                            <ul>
                                {" "}
                                <li>Servicio rápido y eficiente, abarcando una superficie entre 3 a 5 m2 por minuto.</li>
                                <li>Sin riesgo de caídas o accidentes por trabajos en altura.</li>
                                <li>Sin necesidad de instalar andamios o plataformas.</li>
                                
                            </ul>
                        </div>
                        <div className="dos">
                            <ul>
                                {" "}
                                <li>Primer Dron de limpieza en Chile certificado por la DGAC.</li>
                                <li>Limpieza de sectores con difícil acceso de manera manual, logrando llegar a 120 metros de altura</li>
                                <li>Servicio amigable y respetuoso con el medioambiente, permite ahorrar agua y utiliza detergentes
                                biodegradables.</li>
                            </ul>
                        </div>
                    </div>
                </Container>
            </section>
         
            <Container maxWidth="xl" sx={{
                width: '100%', marginTop: '50px', marginBottom: '50px', display: 'flex', padding: '0px', paddingLeft: '0px', paddingRight: '0px', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', '@media (max-width: 600px)': {
                    padding: '0px', paddingLeft: '0px', paddingRight: '0px',
                },
            }}>

                <section className="boton-descarga">
                    <button className="descarga" onClick={openPopup}>Solicitar más información</button>
                </section>
            </Container>
            <Container maxWidth="md" sx={{ marginTop: '50px', marginBottom: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <div className="card-brochure" data-aos="zoom-out">
                    <div className="infobrochure">
                        <h2>Brochure</h2>
                        <h3>Comercial</h3>
                    </div>
                    <div className="brochure-descarga">
                    <a href={pdf} download target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: 'inherit'}}>

                            <button> <img src={descargar} alt="axinntus servicios"  title='axinntus servicios' /> Descargar</button>

                        </a>
                    </div>{" "}
                </div>
            </Container>
            <Modal
                open={popupVisible}
                onClose={closePopup}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 800,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                        outline: 0,
                        '@media (max-width: 900px)': {
                            width: '90%',
                            p: 1,
                        },
                    }}
                >
                    <Button
                        onClick={closePopup}
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                        }}
                    >
                        <CloseIcon />
                    </Button>
                    <Formulario />
                </Box>
            </Modal>
        </>
    );
}
