import React, { useState, useEffect, useCallback } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { IoClose } from "react-icons/io5";
import { Grid, Container } from "@mui/material";
import "../../style/sostenibilidad/videosos.css";

/* Importar imágenes */

import imagen1 from "../../img/sostenibilidad/video/sos.png";
import pil from "../../img/sostenibilidad/portada/pil-sost.png";
import pil1 from "../../img/sostenibilidad/portada/pil-sost-1.png";
import pilmedio1 from "../../img/sostenibilidad/portada/pil-sost-1ab.png";
import pil2 from "../../img/sostenibilidad/portada/pil-sost-2.png";
import pilmedio2 from "../../img/sostenibilidad/portada/pil-sost-2ab.png";
import pil3 from "../../img/sostenibilidad/portada/pil-sost-3.png";
import pilmedio3 from "../../img/sostenibilidad/portada/pil-sost-3ab.png";
import pil4 from "../../img/sostenibilidad/portada/pil-sost-4.png";
import pilmedio4 from "../../img/sostenibilidad/portada/pil-sost-4ab.png";

const data = [
  { original: pil1, medio: pilmedio1 },
  { original: pil2, medio: pilmedio2 },
  { original: pil3, medio: pilmedio3 },
  { original: pil4, medio: pilmedio4 }
];

// Componente para manejar el popup
const VideoPopup = ({ onClose }) => (
  <div className="popup-overlay" onClick={onClose}>
    <div className="popup-content">
      <iframe
        className="video-iframe-sos"
        src="https://www.youtube.com/embed/sFthYenWuL8?si=K1-3vqvtFoiDC5Vm"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
      <button className="close-button-video-sos" onClick={onClose}>
        <IoClose />
      </button>
    </div>
  </div>
);

export default function Sostenibilidadvideo() {
  const [popupVisible, setPopupVisible] = useState(false);
  const [imagenSeleccionada, setImagenSeleccionada] = useState(null);

  const openPopup = useCallback(() => {
    console.log("Popup opened");
    setPopupVisible(true);
  }, []);

  const closePopup = useCallback(() => {
    setPopupVisible(false);
  }, []);

  useEffect(() => {
    AOS.init({ duration: 1500, once: true });

    return () => AOS.refresh(); // Cleanup AOS on component unmount
  }, []);

  const handleClicImagen = useCallback((index) => {
    setImagenSeleccionada(index === imagenSeleccionada ? null : index);
  }, [imagenSeleccionada]);

  return (
    <Container maxWidth="md" sx={{ marginTop: '0px', marginBottom: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
      <section className="video-sos" data-aos="fade-down">
        <h2>Nuestros pilares de Sostenibilidad</h2>
        <div className="sos-video">
          <img src={imagen1} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos" title="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos" />
          <button className="button" onClick={openPopup}>
            <svg viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="26px">
              <path
                d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
                fill="currentColor"
              ></path>
            </svg>
          </button>
        </div>
      </section>

      {popupVisible && <VideoPopup onClose={closePopup} />}

      <section className="imagen-sos-video">
        <section data-aos="zoom-in-down">
          <div className="videoima">
            <img src={pil} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos" title="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos" data-aos="zoom-in-down" />
          </div>
        </section>
        <div className="pilares" data-aos="fade-down">
          <Grid container>
            {data.map((item, index) => (
              <Grid key={index} item xs={12} sm={6} md={3}>
                <div className="todalaimagen">
                  <img src={item.medio} className="imagenSeleccionada" alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos" title="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos" onClick={() => handleClicImagen(index)} />
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </section>
    </Container>
  );
}