
import React, {  useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Grid, Container } from "@mui/material";
import "../../style/seguridad/foco.css";

/* imagenes */
import imagen from "../../img/seguridad/foco/epa.png";
import uno from "../../img/seguridad/foco/epa-agua.png"
import dos from "../../img/seguridad/foco/epa-suelo.png"
import tres from "../../img/seguridad/foco/epa-ruido.png"
import cuatro from "../../img/seguridad/foco/epa-patrimonio.png"
import cinco from "../../img/seguridad/foco/epa-biodiversidad.png"
import seis from "../../img/seguridad/foco/epa-aire.png"

export default function Seguridadestandaresproteccion() {

    useEffect(() => {
        // Inicializa AOS con opciones de configuración si es necesario
        AOS.init({
          duration: 1000, // Duración de las animaciones en milisegundos
          once: true, // Solo realiza las animaciones una vez
        });
      }, []);

return(
    <Container maxWidth="md" sx={{marginTop:'50px', marginBottom:'100px'}}>
    <section className="estandaresproteccion" >
        <h1>Estándares de Protección Ambiental</h1>
        <div className="proteccion-estandar">
            <Grid container>
                <Grid item xs={12} sm={6}>
<div className="titulo-proteccion">
                <img src={imagen} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' data-aos="zoom-in-left"/>
                <p>El estándar de protección ambiental agua, aire, suelo,
ruido, biodiversidad y patrimonio cultural, aplica a todas
las operaciones de Axinntus que son susceptibles a
generar impactos ambientales en las distintas etapas de
un proceso o servicio, considerando la participación de
personal propio, clientes, contratistas y proveedores.</p>
            </div>
                </Grid>
                <Grid  item xs={12} sm={6} >
                 <div className="ciruculosgrupo"> 
                     <Grid container>
                    
                   <Grid item xs={6} sm={6} md={4}>
                    <div className="contiene-img-epa"  data-aos="zoom-in">
                         <img src={uno} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' data-aos="zoom-in-left" /></div>
                   </Grid>
                   <Grid  item xs={6} sm={6} md={4} >
                    
                   <div className="contiene-img-epa" data-aos="zoom-in"> 
                    <img src={dos} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' data-aos="zoom-in-left" /></div>
                   </Grid>
                   <Grid item xs={6} sm={6} md={4}>
                   <div className="contiene-img-epa" data-aos="zoom-in">
                     <img src={tres} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' data-aos="zoom-in-left" /></div>
                   </Grid>
                   <Grid  item xs={6} sm={6} md={4}>
                    <div className="contiene-img-epa" data-aos="zoom-in">
                         <img src={cuatro} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' data-aos="zoom-in-left"/>
                         </div>
                         </Grid>
                   <Grid item xs={6} sm={6} md={4}>
                    <div className="contiene-img-epa" data-aos="zoom-in">
                         <img src={cinco} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' data-aos="zoom-in-left" />
                         </div>
                         </Grid>
                   <Grid item xs={6} sm={6} md={4}>
                    <div className="contiene-img-epa" data-aos="zoom-in"> 
                        <img src={seis} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' data-aos="zoom-in-left" />
                        </div>
                        </Grid>
                   </Grid></div>

                </Grid>
            </Grid>
            
            
        </div>
    
    </section>
    </Container>
)
}