import React from "react";
import Nosotrosportada from "../../../components/nosotros/portada";
import { IoIosArrowForward } from "react-icons/io";
import { Container } from "@mui/material";
import { Helmet } from "react-helmet";

 

export default function Nosotrosport() {
  
    return(<>
    <Helmet>
        <title>Axinntus | Quienes somos Axinntus.</title>
        <meta
          name="description"
          content="Empresa especializada en la entrega de servicios industriales seguros, confiables y de excelencia."
        />
        <meta
          name="keywords"
          content="Axinntus, mantenimientos industriales, operación industriales, equipos multiculturalidad, equipos de excelencia, trabajar en Axinntus, personas, seguridad, activos"
        />
      </Helmet>
        <Container maxWidth="xl" sx={{marginTop:'-40px','@media (max-width: 960px)':{
                marginTop:'0px'
              },}} >
            <div 
            className="paginacompleta"
           >
            <div style={{
                display:"flex", alignItems:"center", fontSize:"0.8rem" 
            }}> <p style={{ fontWeight:"400", color:"#898b8a"}}> Sobre Nosotros</p> <IoIosArrowForward style={{marginLeft:"10px", marginRight:"10px", color:"#6f7170"}}/> <strong style={{ color:"#6f7170"}}> Quiénes Somos</strong></div>
            </div>
        <Nosotrosportada />
       
        
        </Container></>
    )

}