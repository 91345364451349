import React from "react"
import Portadahome from "../../components/home/portada"
import Serviciohome from "../../components/home/servicios"
import Clienteshome from "../../components/home/clientes"
import Nosotroshome from "../../components/home/nosotros"
import Certificacionhome from "../../components/home/acreditacion"




export const Home = () => {

  return (
    <> 
     <><Portadahome/></> 
     <><Serviciohome/></> 
    <><Clienteshome/></> 
    <><Nosotroshome/></> 
    <><Certificacionhome/></> 
    </>
   )

}
  

