import { IoIosArrowForward } from "react-icons/io";
import { Container } from "@mui/material";
import Sostenibilidadinclusion from "../../../components/sostenibilidad/inclusion";
import { Helmet } from "react-helmet";


export default function Sostenibilidadinclu() {
  
    return (
      <div>
         <Helmet>
        <title>Axinntus | Inclusión y diversidad</title>
        <meta
          name="description"
          content="IEn Axinntus, entendemos que la multiculturalidad en nuestros equipos enriquece nuestra capacidad para ofrecer servicios de excelencia."
        />
        <meta
          name="keywords"
          content="axinntus, operación industriales, servicios , personas, equipos,  estándares de seguridad, servicios a la medida, operación."
        />
      </Helmet>
        <Container maxWidth="xl" sx={{marginTop:'-40px','@media (max-width: 960px)':{
                marginTop:'0px'
              },}} >
          <div
          className="paginacompleta"
    
  >
    <div
      style={{ display: "flex", alignItems: "center", fontSize: "0.8rem" }}
    >
      {" "}
      <p style={{ fontWeight: "400", color: "#898b8a" }}>
        {" "}
       Sostenibilidad
      </p>{" "}
      <IoIosArrowForward
        style={{
          marginLeft: "10px",
          marginRight: "10px",
          color: "#6f7170",
        }}
      />{" "}
      <strong style={{ color: "#6f7170" }}>
 
Inclusión y diversidad</strong>
    </div>
  </div>
</Container>

  <Sostenibilidadinclusion />

      </div>
    );
  }