
import React, { useState, useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Modal, Box, Container } from "@mui/material";
import { IoClose } from "react-icons/io5";
import "../../style/seguridad/reglas.css";


/* imagenes */
import imagen from "../../img/seguridad/regla/regla.png";
import imagen1 from "../../img/seguridad/regla/titulo.png";
import imagen2 from "../../img/seguridad/regla/reglasdeoro.png";

// Estilos del modal
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%', // Ajustar el tamaño del modal
  maxWidth: '800px', // Ancho máximo del modal
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 0,
  outline: 0, // Eliminar el contorno para personalizar el modal
  borderRadius: '8px', // Bordes redondeados
};

export default function Seguridadregla() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duración de las animaciones en milisegundos
      once: true, // Solo realiza las animaciones una vez
    });
  }, []);

  const [popupVisible, setPopupVisible] = useState(false);

  const openPopup = () => {
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
  };

  return (
  
    <section className="reglasdeoro" data-aos="fade-down">
       <Container maxWidth="md" sx={{marginTop:'50px', marginBottom:'100px', display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
      <div>
        <img
          src={imagen1}
          alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"
          title="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"
          className="imagentitulo"
        />
      </div>
      <section className="titulo-regla-oro">
        <span>Para el Cuidado de la Vida y la protección</span>
        <span>del entorno socioambiental</span>
      </section>
      <section className="contiene-imagenes-regla-oro">
        <img
          src={imagen}
          alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"
          title="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"
          className="imageninfo"
        />
      </section>
      <section className="video-regla">
        <div className="sos-video">
          <img
            src={imagen2}
            alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"
            title="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"
          />
          <button className="button" onClick={openPopup}>
            <svg
              viewBox="0 0 448 512"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              width="26px"
            >
              <path
                d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
                fill="currentColor"
              ></path>
            </svg>
          </button>
        </div>
      </section>
      </Container>
      <Modal
        open={popupVisible}
        onClose={closePopup}
        aria-labelledby="video-modal-title"
        aria-describedby="video-modal-description"
        disableAutoFocus
        disableEnforceFocus
      >
        <Box sx={modalStyle}>
          <button className='buttoncloseform' onClick={closePopup} >
            <IoClose size={24} />
          </button>
          <Box sx={{ mt: 2 }}>
            <iframe
              width="100%"
              height="400px"
              src="https://www.youtube.com/embed/gAeYMU52DGM?si=IBbQmAFXF69UVOYd"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </Box>
        </Box>
      </Modal>
    </section>
  );
}