
import React, { useState, useEffect } from "react";
import { Formulario } from "../../formulario/formulario";
import AOS from 'aos';
import 'aos/dist/aos.css';
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import { Grid , Container, Modal, Box} from "@mui/material";
import "../../../style/servicios/manejo/manejo.css";

/* imagenes */
import pdf from "../../../img/pdf/BROCHURE-AXNN-2024.pdf";
import imagen from "../../../img/servicios/otros/fotos-servicios-otros.jpg";
import imagen1 from "../../../img/servicios/otros/fotos-servicios-otros-1.jpg";
import imagen2 from "../../../img/servicios/otros/fotos-servicios-otros-2.jpg";
import imagen3 from "../../../img/servicios/otros/fotos-servicios-otros-3.jpg";
import imagen4 from "../../../img/servicios/otros/fotos-servicios-otros-4.jpg";
import descargar from "../../../img/servicios/otros/boton-descargar.png"


export default function Solucionesindustriales() {
  useEffect(() => {
    // Inicializa AOS con opciones de configuración si es necesario
    AOS.init({
      duration: 1000, // Duración de las animaciones en milisegundos
      once: true, // Solo realiza las animaciones una vez
    });
  }, []); 
  
    const [popupVisible, setPopupVisible] = useState(false);
  
    const openPopup = () => {
      setPopupVisible(true);
    };
  
    const closePopup = () => {
      setPopupVisible(false);
    };
  return (
    <>
     <Container maxWidth="md" sx={{marginTop:'50px', marginBottom:'100px', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
        <Grid container sx={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:"0px"}} data-aos="fade-down">
        <Grid item xs={12} sm={4} sx={{ display:'flex', alignItems:'center', justifyContent:'center',marginTop:"0px"}}>
            <div className="contiene-imagen">
              <img src={imagen} alt="axinntus  mantenimiento  industriales activos" title='axinntus  mantenimiento  industriales activos' />
            </div>
          </Grid>
          <Grid item xs={12} sm={8} sx={{width:'100%', paddingRight:'40px',  '@media (max-width: 600px)': {
               padding:'0px', paddingLeft:'0px', paddingRight:'0px',
            },}}>
            <div className="contiene-informacion-manejo">
              <h1 alt="axinntus  mantenimiento  industriales activos"  title='axinntus  mantenimiento  industriales activos'>Otras soluciones</h1>
              <h1 alt="axinntus  mantenimiento  industriales activos"  title='axinntus  mantenimiento  industriales activos'>industriales a la medida</h1>
              
              <div className="descripcion">
                <span>
                Ofrecemos soluciones a los  <strong>requerimientos
esporádicos asociados a actividades o proyectos
a corto plazo,</strong> cumpliendo todas las necesidades
del cliente de acuerdo a sus estándares,
adecuándonos en tiempo y forma con un servicio
flexible y a la medida.
                </span>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
      <section className="unoproductos">
      <Container maxWidth="md" sx={{marginTop:'50px', marginBottom:'50px', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
      <div className="titulo-productos">
          <h2>Algunos de los servicios que realizamos son:</h2>

        </div>
        <div className="lista">
          <div className="uno">
            <ul>
              {" "}
              <li> Traslado de material a través de camión tracto-batea.
</li>
              <li>Operación de encarpe, desencarpe y limpieza de equipos.</li>
              <li>Limpieza de estructuras a nivel de piso y altura.</li>
              <li>Limpieza en sistemas de correas transportadoras.</li>
              <li>Retiro de material desde estanques y servicio de aspirado mediante camión alto vacío.</li>
              <li>Limpieza industrial especializado en áreas complejas (espacio confinado).</li>
            </ul>
          </div>
          
        </div>
       </Container>
      </section>
      <Container maxWidth="md" sx={{marginTop:'50px', marginBottom:'100px', width:'100%'}}>
        <div className="info-operaciones">
          <div className="linea"></div>
          <div className="todo-operacion">
            <h2>CLIENTES ATENDIDOS</h2>
            <div className="descripcionesoperaciones">
              <div>
                
                <strong>Engie</strong> 
                <strong>Noracid</strong> 
                <strong>Terminal graneles del norte (TGN)</strong> 
                <strong>Minera Sierra Gorda</strong> 
        <strong>Aes Gener</strong >
                <strong>Polpaico</strong >
              </div>
             
            </div>
          </div>
        </div>
      </Container>
      <Container maxWidth="xl" sx={{ width:'100%', marginTop:'50px', marginBottom:'50px', display:'flex', padding:'0px', paddingLeft:'0px', paddingRight:'0px', alignItems:'center', justifyContent:'center', flexDirection:'column',  '@media (max-width: 600px)': {
               padding:'0px', paddingLeft:'0px', paddingRight:'0px',
            },}}>
        <div className=" galcontieneimagen" >
          <Grid container>
            <Grid item xs={3}>
              <div><img src={imagen1} alt="axinntus servicios, industriales, mantenimiento, operación, activos" title='axinntus servicios, industriales, mantenimiento, operación, activos'/></div>
            </Grid>
            <Grid item xs={3}>
              <div><img src={imagen2}  alt="axinntus servicios, industriales, mantenimiento, operación, activos" title='axinntus servicios, industriales, mantenimiento, operación, activos'/></div>
            </Grid>
            <Grid item xs={3}>
              <div><img src={imagen3}  alt="axinntus servicios, industriales, mantenimiento, operación, activos" title='axinntus servicios, industriales, mantenimiento, operación, activos'/></div>
            </Grid>
            <Grid item xs={3}>
              <div><img src={imagen4}   alt="axinntus servicios, industriales, mantenimiento, operación, activos" title='axinntus servicios, industriales, mantenimiento, operación, activos'/></div>
            </Grid>
          </Grid>
        </div>
        <section className="boton-descarga">
          <button className="descarga" onClick={openPopup}>Solicitar más información</button>
        </section>
      </Container>
      <Container maxWidth="md" sx={{marginTop:'50px', marginBottom:'50px', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
        <div className="card-brochure" data-aos="zoom-out">
          <div className="infobrochure">
            <h2>Brochure</h2>
            <h3>Comercial</h3>
          </div>
          <div className="brochure-descarga">
          
          <a href={pdf} download target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: 'inherit'}}>
                <button> <img src={descargar} alt="axinntus servicios" title='axinntus servicios'  /> Descargar</button>
           
            </a>
          </div>{" "}
        </div>
      </Container>
      <Modal
        open={popupVisible}
        onClose={closePopup}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
       <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 800,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            outline: 0,
            '@media (max-width: 900px)':{
              width:'90%',
              p: 1,
            },
          }}
        >
          <Button
            onClick={closePopup}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
            }}
          >
            <CloseIcon />
          </Button>
          <Formulario />
        </Box>
      </Modal>
    </>
  );
}
