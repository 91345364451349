
import React, {  useEffect } from 'react';
import "../../style/nosotros/mapa.css"
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Container } from "@mui/material";


import portada from "../../img/nosotros/mapa/portada.png"
import mapa from "../../img/nosotros/mapa/mapa.jpg"

export default function Nosotrosmapa() {
    useEffect(() => {
        // Inicializa AOS con opciones de configuración si es necesario
        AOS.init({
          duration: 1000, // Duración de las animaciones en milisegundos
          once: true, // Solo realiza las animaciones una vez
        });
      }, []); 
return(
    <Container maxWidth="xl" sx={{marginTop:'50px', marginBottom:'100px', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
<section className="portadamapa" data-aos="fade-down">
   
        <img src={portada} alt='servicios de operación industriales activos 'title='servicios de operación industriales activos '  />
       <div className='contienentitulomapa'> <h1>Nuestras </h1> <h1>operaciones</h1> <h1>y contratos</h1></div>

</section>
<section className="titulo-dos-mapa">
<h2>Presencia Nacional</h2>
<div>Desde la II a VIII región</div>

</section>
<section  style={{marginBottom:"150px"}} className="todomapa" data-aos="zoom-in">
<img src={mapa} alt='servicios de operación industriales activos 'title='servicios de operación industriales activos ' className='mapa'  />
</section>
    </Container>
)
}