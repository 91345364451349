
import Seguridadestandares from "../../../components/seguridad/estandares";
import { IoIosArrowForward } from "react-icons/io";
import { Container } from "@mui/material";
import { Helmet } from "react-helmet";


export default function Seguridadestandare() {
    
    return(
        <>
         <Helmet>
        <title>Axinntus | Estándares Riesgos Críticos 4 A+C.</title>
        <meta
          name="description"
          content="Focos de seguridad y socioambientales de Ultramar."
        />
        <meta
          name="keywords"
          content="axinntus, operación industriales, servicios , personas, estándares de seguridad, servicios a la medida, eficiencia."
        />
      </Helmet>
           <Container maxWidth="xl" sx={{marginTop:'-40px','@media (max-width: 960px)':{
                marginTop:'0px'
              },}} ><div
        className="paginacompleta"
    
  >
    <div
      style={{ display: "flex", alignItems: "center", fontSize: "0.8rem" }}
    >
      {" "}
      <p style={{ fontWeight: "400", color: "#898b8a" }}>
        {" "}
        Seguridad
      </p>{" "}
      <IoIosArrowForward
        style={{
          marginLeft: "10px",
          marginRight: "10px",
          color: "#6f7170",
        }}
      />{" "}
      <strong style={{ color: "#6f7170" }}>Estándares Riesgos Críticos 4 A+C</strong>
    </div>
  </div></Container>
        <Seguridadestandares/>

        </>
    )
}