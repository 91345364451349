import Sostenibilidadrelansamiento from "../../../components/sostenibilidad/relansamiento";
import { Helmet } from "react-helmet";

export default function Sostenibilidadrel() {
  
    return (
      <div>
     <Helmet>
        <title>Axinntus | Relacionamiento con grupos de interés</title>
        <meta
          name="description"
          content="En Axinntus, Por medio de diversas iniciativas y programas, buscamos generar un impacto positivo y sostenible, manteniendo un diálogo constante con cada uno de ellos."
        />
        <meta
          name="keywords"
          content="axinntus, operación industriales, servicios , personas, equipos,  estándares de seguridad, servicios a la medida, operación."
        />
      </Helmet>

<Sostenibilidadrelansamiento  />

      </div>
    );
  }