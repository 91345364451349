import Limpieza from "../../../components/servicios/limpieza/limpieza";
import { IoIosArrowForward } from "react-icons/io";
import { Container } from "@mui/material";
import { Helmet } from "react-helmet";


export default function Limpiezaindustrial() {

    return(
        <>
         <Helmet>
        <title>Axinntus | Limpieza industrial.</title>
        <meta
          name="description"
          content="Enmarcados en altos estándares de seguridad y cuidado del entorno, Axinntus ofrece aseo y aspirado industrial para empresas del rubro energía y minería, asegurando eficiencia y bienestar."
        />
        <meta
          name="keywords"
          content="Axinntus, mantenimientos industriales, operación industriales, equipos multiculturalidad, equipos de excelencia, trabajar en Axinntus, personas, seguridad, activos."
        />
      </Helmet>
 <Container maxWidth="xl" sx={{marginTop:'-40px','@media (max-width: 960px)':{
                marginTop:'0px'
              },}} >
<div
className="paginacompleta"
   
  >
    <div
      style={{ display: "flex", alignItems: "center", fontSize: "0.8rem" }}
    >
      {" "}
      <p style={{ fontWeight: "400", color: "#898b8a" }}>
        {" "}
        Nuestros servicios
      </p>{" "}
      <IoIosArrowForward
        style={{
          marginLeft: "10px",
          marginRight: "10px",
          color: "#6f7170",
        }}
      />{" "}
      <strong style={{ color: "#6f7170" }}>
      Limpieza
industrial</strong>
    </div>
  </div></Container>
        <Limpieza/>
        </>
    )
}