import { IoIosArrowForward } from "react-icons/io";
import { Container } from "@mui/material";
import { Helmet } from "react-helmet";
import Sostenibilidadgaleria from "../../../components/sostenibilidad/galeria";


export default function Sostenibilidadgal() {
  
    return (
      <div>
        <Helmet>
        <title>Axinntus | Galería de fotos Actividades más destacadas en el cuidado del medio ambiente y social.</title>
        <meta
          name="description"
          content="Iniciativas y proyectos de medioambiente que se aplica a todo tipo de operación de Axinntus que son susceptibles a generar impactos ambientales en las distintas etapas de un proceso o servicios, considerando la participación de personas."
        />
        <meta
          name="keywords"
          content="axinntus, operación industriales, servicios , personas, estándares de seguridad, servicios a la medida, eficiencia."
        />
      </Helmet>
           <Container maxWidth="xl" sx={{marginTop:'-40px','@media (max-width: 960px)':{
                marginTop:'0px'
              },}} >
              <div
              className="paginacompleta"
    
  >
    <div
      style={{ display: "flex", alignItems: "center", fontSize: "0.8rem" }}
    >
      {" "}
      <p style={{ fontWeight: "400", color: "#898b8a" }}>
        {" "}
       Sostenibilidad
      </p>{" "}
      <IoIosArrowForward
        style={{
          marginLeft: "10px",
          marginRight: "10px",
          color: "#6f7170",
        }}
      />{" "}
      <strong style={{ color: "#6f7170" }}>
 
      Galería de fotos
Actividades más destacadas</strong>
    </div>
  </div>
</Container>

<Sostenibilidadgaleria  />
      </div>
    );
  }