import Seguridadgaleria from "../../../components/seguridad/galeria";
import { IoIosArrowForward } from "react-icons/io";
import { Container } from "@mui/material";
import { Helmet } from "react-helmet";

export default function SeguridadGaleria() {
   
    return(
        <>
         <Helmet>
        <title>Axinntus | Galería de fotos Actividades más destacadas Seguridad.</title>
        <meta
          name="description"
          content="Iniciativas y proyectos de medioambiente que se aplica a todo tipo de operación de Axinntus que son susceptibles a generar impactos ambientales en las distintas etapas de un proceso o servicios, considerando la participación de personas."
        />
        <meta
          name="keywords"
          content="axinntus, operación industriales, servicios , personas, estándares de seguridad, servicios a la medida, eficiencia."
        />
      </Helmet>
              <Container maxWidth="xl" sx={{marginTop:'-40px','@media (max-width: 960px)':{
                marginTop:'0px'
              },}} >
                 <div
              className="paginacompleta"
  
  >
    <div
      style={{ display: "flex", alignItems: "center", fontSize: "0.8rem" }}
    >
      {" "}
      <p style={{ fontWeight: "400", color: "#898b8a" }}>
        {" "}
        Seguridad
      </p>{" "}
      <IoIosArrowForward
        style={{
          marginLeft: "10px",
          marginRight: "10px",
          color: "#6f7170",
        }}
      />{" "}
      <strong style={{ color: "#6f7170" }}>Galería de fotos
Actividades más destacadas</strong>
    </div>
  </div></Container> 
      <Seguridadgaleria/>
        </>
    )
}