import Seguridadportada from "../../../components/seguridad/portada";
import { Helmet } from "react-helmet";


export default function Seguridadpor() {
    
    return(
        <>
         <Helmet>
        <title>Axinntus | Política de Seguridad, Salud Ocupacional, Medioambiente y Calidad.</title>
        <meta
          name="description"
          content="Nuestro compromiso es la seguridad de nuestros equipos, prevenir accidentes y enfermedades laborales, y minimizar nuestro impacto ambiental."
        />
        <meta
          name="keywords"
          content="axinntus,  industriales, servicios , personas, estándares de seguridad, servicios a la medida, eficiencia."
        />
      </Helmet>
        <Seguridadportada />
        </>
    )
}