import { Grid,  SwipeableDrawer } from '@mui/material';
import { Link } from 'react-router-dom';
import "../../style/navbar/navbar.css"

const NavBarMovil = ({ logo, state, toggleDrawer, list }) => (
    
  <>
    <section className="navbar-movil-superior">
      <Grid container>
        <Grid item xs={6}>
          <div className="contenedor-imagen-logo">
            <Link to='/' >
              <img
                src={logo}
                alt="axinntus"
                
                title="axinntus"
                className="logo-mobil-Axinntus"
              />
            </Link>
          </div>
        </Grid>
        <Grid item xs={6} className="menu">
          <label className="burger" htmlFor="burger">
            <input
              type="checkbox"
              id="burger"
              checked={state.right}
              onChange={toggleDrawer("right", !state.right)}
            />
            <span></span>
            <span></span>
            <span></span>
          </label>
          <SwipeableDrawer
            className="listado-menu"
            anchor="right"
            open={state.right}
            onClose={toggleDrawer("right", false)}
            onOpen={toggleDrawer("right", true)}
          >
            {list("right")}
          </SwipeableDrawer>
        </Grid>
      </Grid>
    </section>
   
  </>
);

export default NavBarMovil;