import React, { useState } from "react";
import "../../style/footer/footer.css";
import { styled } from '@mui/system';
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { FaRegCircleCheck } from "react-icons/fa6";
import { BiErrorCircle } from "react-icons/bi";
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from "react-router-dom";
import {
  Grid,
  Container,
  TextField,
  Modal,
  Box

} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";


/* imagenes */
import Logo from "../../img/footer/logo.png";
import correo from "../../img/footer/correo.png";
import ubicacion from "../../img/footer/ubicacion.png";
import pdf from "../../img/pdf/PoliticasSeguridad2024.pdf";





  const StyledTextField = styled(TextField)(({ theme, error }) => ({
    border:'0px',
    fontSize:'0.8rem',
    fontFamily:'Compasse',
    background:'#fff',
    color: '#2c3543',
    borderRadius:'50px',
    '& .MuiOutlinedInput-input':{
      border:'0px',
      padding:'10px 14px',
      fontSize:'0.7rem',
    },
    '& .MuiOutlinedInput-notchedOutline' :{
      border: '0px', 
    },
    '& .MuiInputLabel-root':{
      fontSize:'0.7rem',
      background:'#fff',
      color: '#2c3543',
      borderRadius:'10px',
    padding:'0px 5px'
    },
  }));

  const StyledTextFieldtext = styled(TextField)(({ theme, error }) => ({
    border:'0px',
    fontSize:'0.8rem',
    fontFamily:'Compasse',
    background:'#fff',
    color: '#2c3543',
    borderRadius:'5px',
    '& .MuiOutlinedInput-input':{
      border:'0px',
      padding:'10px 14px',
      fontSize:'0.7rem',
    },
    '& .MuiOutlinedInput-notchedOutline' :{
      border: '0px', 
    },
    '& .MuiInputLabel-root':{
      fontSize:'0.7rem',
      background:'#fff',
      color: '#2c3543',
      borderRadius:'10px',
    padding:'0px 5px'
    },
    }));
  

    export const Footer = () => {
  const [loading, setLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState(null);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [enviandoAlert, setenviandoAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [formData, setFormData] = useState({
    nombre: "",
    asunto: "",
    correo: "",
    mensaje: "",
  });
  const handleEmailClick = () => {
    window.location.href = 'mailto:comercial@axinntus.com';
  };
  const handleEmailcontacrClick = () => {
    window.location.href = 'mailto:contacto@axinntus.com';
  };
  const handleChange = (event, fieldName) => {
    const { value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setenviandoAlert(true);
    try {
      const response = await fetch("https://www.mailaxinntus.axinntus.com/mailGeneral.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        setenviandoAlert(false);
        setSuccessAlertOpen(true);
        setAlertMessage('Su mensaje fue enviado con éxito.');

        setFormData({
          nombre: "",
          asunto: "",
          correo: "",
          mensaje: "",
        });
      } else {
        setenviandoAlert(false);
        setErrorAlertOpen(true);
    setAlertMessage('error al enviar.');
    setTimeout(() => {
     
      setErrorAlertOpen(false);
    }, 4000);
      }
    } catch (error) {
      setenviandoAlert(false);
      setErrorAlertOpen(true);
  setAlertMessage('Error al enviar el mensaje, vuelva a intentar.');
    }
    finally {
      setTimeout(() => {
        setSuccessAlertOpen(false);
        setErrorAlertOpen(false);
      }, 4000);
    }
  };
  
  const handleClose = () => {
    // Cierra el modal o popup
    setResponseStatus(null);
  };
  return (
    <>
      <section className="footer">
        <Container maxWidth="md" sx={{marginTop:'0px', marginBottom:'30px', display:'flex', alignItems:'center', justifyContent:'center', padding:'100px 0px 0px 0px',  '@media (max-width: 980px)': {
              padding:'50px 10px 20px 10px',
            }, }}>
        <Grid container sx={{display:"flex", alignItems:"flex-start", justifyContent:"center"}} >
          <Grid item xs={12} md={4} sx={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}} >
            <div className="container-footer-izquierdo">
              <div className="integrar-img-logo-footer">
              <Link to="/" style={{cursor:"pointer"}}> 
               <img
                  src={Logo}
                  alt="axinntus"
                  
                  title="axinntus"
                 
                /></Link>
              </div>
              <div className="servicios-informacion-footer">
                <h1 className="titulo-info-footer" alt="servicios axinntus" >
                  {" "}
                  Servicios Industriales Limitada
                </h1>
                <div className="contacto-footer">
                  <div className="imagen-footer-icono">
                    
                    <img src={ubicacion}  alt="servicios axinntus"  title="servicios axinntus" />
                  </div>
                  <div className="info-footer">
                    <h5 className="subtitulo-ontacto-footer" alt="servicios axinntus" >Casa Matriz:</h5>
                    <span className="info-footer-izquierdo" alt="servicios axinntus" >
                    Av. Balmaceda 2472, piso 13, Antofagasta.
                    </span>
                  </div>
                </div>
                <div className="contacto-footer-dos">
                  <div className="imagen-footer-correoicono">
                    <img src={correo}  alt="servicios axinntus"  title="servicios axinntus" />
                  </div>

                  <span className="info-footer-contacto-izquierdo" alt="servicios axinntus" onClick={handleEmailcontacrClick} style={{cursor:"pointer"}} >
                    contacto@axinntus.com
                  </span>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4} sx={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}} >
            <div className="inforacion-completa-footer">
              <div className="politicas-seguridad-footer">
                <h1 className="titlulo-politicas-seguridad" alt="servicios axinntus" >
                  Política de Seguridad de la Información
                </h1>
              
                <a href={pdf} download target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: 'inherit'}}>
                    <button
                      className="boton-documento-footer"
                      variant="outlined"
                    >
                      Ver documento{" "}
                      <KeyboardArrowDownIcon
                        style={{
                          transform: "rotate(270deg)",
                          fontSize: "30px",
                        }}
                      />
                    </button>
                  </a>
              
              </div>
              <div className="contacto-comercial">
                <h1 className="titlulo-politicas-seguridad" alt="servicios axinntus" >
                  Contacto comercial
                </h1>
                <div className="contacto-footer-tres">
                  <div className="imagen-footer-correoicono">
                    <img src={correo} alt="servicios axinntus"  title="servicios axinntus" />
                  </div>
                  <span className="info-footer-contacto-izquierdo" onClick={handleEmailClick} style={{cursor:"pointer"}}>
                    comercial@axinntus.com
                  </span>
                </div>
              </div>
              <div className="redes-sociales-footer">
                <button className="contacto-icono-redes">
                  <a
                    href="https://www.instagram.com/axinntus/"
                    target="_blank"
                  >
                    <FaInstagram className="iconos-footer" />
                  </a>
                </button>
                <button className="contacto-icono-redes">
                  <a
                    href="https://www.linkedin.com/company/axinntus?originalSubdomain=cl"
                    target="_blank"
                  >
                    <FaLinkedinIn className="iconos-footer" />
                  </a>
                </button>
                <button className="contacto-icono-redes">
                  <a
                    href="https://www.facebook.com/Axinntus/?locale=es_LA"
                    target="_blank"
                  >
                    {" "}
                    <FaFacebookF className="iconos-footer" />
                  </a>
                </button>
                <button className="contacto-icono-redes">
                  <a
                    href="https://www.youtube.com/@axinntus7615"
                    target="_blank"
                  >
                    {" "}
                    <FaYoutube className="iconos-footer" />
                  </a>
                </button>
              </div>
            </div>
          </Grid>
          <Grid item  xs={12} md={4}  sx={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}} >
            <div className="formulario-footer">
              <h1 className="titulo-formulario-footer">Escríbenos</h1>
              <form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12} sx={{marginBottom:'10px'}} className="contenedor">
               <StyledTextField
                id="nombre"
                name="nombre"  
                className="input-formulario"
                label="Nombre"
                variant="outlined"
                fullWidth
                value={formData.nombre}
                onChange={(e) => handleChange(e, "nombre")}
                />
                  </Grid>
                </Grid>{" "}
                <Grid item xs={12} sx={{marginBottom:'10px'}} className="contenedor">
                <StyledTextField
   id="correo"
  name="correo"
  className="input-formulario"
  label="Correo Electrónico"
  variant="outlined"
  type="email"
  fullWidth
  value={formData.correo}
          onChange={(e) => handleChange(e, "correo")}
/>
                </Grid>
                <Grid item xs={12} sx={{marginBottom:'10px'}} className="contenedor">
                <StyledTextField
  id="asunto"
  name="asunto"
  className="input-formulario"
  label="Asunto"
  variant="outlined"  
  fullWidth
  value={formData.asunto}
          onChange={(e) => handleChange(e, "asunto")}
/>
                </Grid>
                
                <Grid item xs={12} className="contenedor">
                <StyledTextFieldtext
 id="mensaje"
  name="mensaje" 
  className="inputformulario"
  label="Mensaje"
  variant="outlined"
  multiline
  rows={4}
  fullWidth
  value={formData.mensaje}
          onChange={(e) => handleChange(e, "mensaje")}
/>
                </Grid>
                <Grid item xs={12} className="contenedor-button-formulario-footer">
                  <button
                   type="submit"
                    className="enviar-formulario-cotizar"
                  >
                    Enviar
                  </button>
                </Grid>
              </form>
            </div>
          </Grid>
        </Grid>
       </Container>
      </section>
      <section className="footer-final">
        <div className="pie-footer">
          <p className="final-pie"> Axinntus 2024</p>
        </div> 
        
      </section>
      <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
      <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
            <h2 id="modal-title" style={{ fontFamily: 'Compasse', color: '#6fa967', fontSize: '1rem', fontWeight: '600',  marginTop:"-10px" }}>Mensaje enviado Correctamente</h2>
            <p style={{ fontFamily: 'Compasse', color: '#585857', fontSize: '1rem', fontWeight: '600', marginTop:"0px" }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de error */}
      <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle  style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Compasse', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      
      <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: '#6fa967' }} />
            <p style={{ fontFamily: 'Compasse', color: '#6fa967', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando mensaje...
            </p>
          </section>
        </Box>
      </Modal>
    </>
  );
}

 