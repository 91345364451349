import React, { useState, useEffect } from 'react';
import '../../style/home/nosotros/nosotros.css';
import { Grid, Container } from '@mui/material';

import discapacidad from '../../img/home/nosotros/discapacidad.png';
import equipos from '../../img/home/nosotros/equipos.png';
import ingresos from '../../img/home/nosotros/ingresos.png';
import femenina from '../../img/home/nosotros/femenina.png';
import personas from '../../img/home/nosotros/personas.png';
import multiculturalidad from '../../img/home/nosotros/multiculturalidad.png';

const dataNosotros = [
    { id: 1, image: personas, title: 'Personas', cantidad: '+', count: '700', seo: 'personas' },
    { id: 2, image: ingresos, title: 'Ingresos/año', cantidad: '+MUSD', count: '32', seo: 'industriales' },
    { id: 3, image: equipos, title: 'Equipos', count: '112', seo: 'equipos' },
    { id: 4, image: femenina, title: 'Dotación femenina', porcentaje: '%', count: '12', seo: 'operación' },
    { id: 5, image: discapacidad, title: 'Personas en situación de discapacidad', porcentaje: '%', count: '1', seo: 'activos' },
    { id: 6, image: multiculturalidad, title: 'Multiculturalidad', porcentaje: '%', count: '11', seo: 'multiculturalidad' },
];

const CountingComponent = ({ isVisible, targetCount, step, interval }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        let intervalId;
        if (isVisible) {
            intervalId = setInterval(() => {
                setCount(prevCount => Math.min(prevCount + step, targetCount));
            }, interval);
        }

        return () => clearInterval(intervalId);
    }, [isVisible, targetCount, step, interval]);

    return <span>{count}</span>;
};

export default function Nosotroshome() {
    const [inView, setInView] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const elementOffset = document.querySelector('.nosotros-home').getBoundingClientRect().top;
            const windowHeight = window.innerHeight;

            // Si el elemento está completamente visible en el viewport
            if (elementOffset < windowHeight) {
                setInView(true);
            } else {
                setInView(false);
            }
        };

        // Agregar evento de scroll
        window.addEventListener('scroll', handleScroll);
        
        // Limpiar el evento al desmontar el componente
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
     <section className="nosotros-home">
            <Container maxWidth="md" sx={{ marginTop: '100px', marginBottom: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Grid container className="contiene-home-nosotros" sx={{ padding: '20px', marginTop: '100px', marginBottom: '50px' }}>
                    {dataNosotros.map((item) => (
                        <Grid key={item.id} item xs={12} sm={4}>
                            <div className="contienen-nosotros-home">
                                <div className="contiene-img-nosotros-home">
                                    <img src={item.image} alt={item.seo} title={item.seo} />
                                </div>
                                <div className="info-home-nosotros">
                                    <h2>{item.title}</h2>
                                    <span>{item.cantidad}</span>
                                    <CountingComponent
                                        isVisible={inView}
                                        targetCount={item.count}
                                        step={Math.ceil(item.count / 50)}
                                        interval={50}
                                    />
                                    <span>{item.porcentaje}</span>
                                </div>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </section> 
    );
}