
import React, { useState, useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import "../../style/nuestroequipo/equipo.css";
import { styled } from '@mui/system';
import { BsGlobe2 } from "react-icons/bs";
import { Grid, TextField,  Container , Modal, Box } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import ClipIcon from "../../img/nuestroequipo/clip.png"; // Importa el ícono de clip como imagen estática
import { IoClose } from "react-icons/io5";
import { FaRegCircleCheck } from "react-icons/fa6";
import { BiErrorCircle } from "react-icons/bi";

/* imagenes */
import imagen from "../../img/nuestroequipo/equipo.jpg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";



const StyledTextField = styled(TextField)(({ theme, error }) => ({
  border:'0px',
  fontSize:'0.8rem',
  fontFamily:'Compasse',
  background:'#fff',
  color: '#2c3543',
  borderRadius:'50px',
  '& .MuiOutlinedInput-input':{
    border:'0px',
    padding:'12px 14px',
    fontSize:'0.7rem',
  },
  '& .MuiOutlinedInput-notchedOutline' :{
    border: '0px', 
  },
  '& .MuiInputLabel-root':{
    fontSize:'0.7rem',
    background:'#fff',
    color: '#2c3543',
    borderRadius:'10px',
  padding:'0px 5px'
  },
  }));

  

export default function Portadanuestroequipo() {
    
  const [menuVisible, setMenuVisible] = useState(false);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [enviandoAlert, setenviandoAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  
  useEffect(() => {
    // Agregar un manejador de redimensión para controlar la visibilidad del menú móvil
    const handleResize = () => {
      if (window.innerWidth <= 599) {
        setMenuVisible(true);
      } else {
        setMenuVisible(false);
      }
    };
  
    // Adjuntar el manejador de redimensionamiento al evento de redimensionamiento del window
    window.addEventListener('resize', handleResize);
  
    // Limpieza del evento al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); 

  useEffect(() => {
    // Inicializa AOS con opciones de configuración si es necesario
    AOS.init({
      duration: 1000, // Duración de las animaciones en milisegundos
      once: true, // Solo realiza las animaciones una vez
    });
  }, []);

  
  const [formData, setFormData] = useState({
    nombre: "",
    rut: "",
    ciudad: "",
    cargo: "",
    archivo: null,
  });
  const handleChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    handleChange("archivo", file);
  };
  const handleRemoveFile = () => {
    setFormData((prevData) => ({
      ...prevData,
      archivo: null,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setenviandoAlert(true);

    const data = new FormData();
    data.append("nombre", formData.nombre);
    data.append("rut", formData.rut);
    data.append("ciudad", formData.ciudad);
    data.append("cargo", formData.cargo);
    data.append("archivo", formData.archivo);

    try {
      const response = await fetch(
        "https://www.mailaxinntus.axinntus.com/emailCV.php",
        {
          method: "POST",
          body: data,
        }
      );
      const status = response.status;
    

      if (status === 200) {
        setenviandoAlert(false);
        setSuccessAlertOpen(true);
        setAlertMessage('Su mensaje fue enviado con éxito.');

        // Restablecer los valores del formulario después de un envío exitoso
        setFormData({
          nombre: "",
          rut: "",
          ciudad: "",
          cargo: "",
          archivo: null,
        });
      } else {
        
          setenviandoAlert(false);
      setErrorAlertOpen(true);
  setAlertMessage('debe agregar curriculum formato PDF.');
  setTimeout(() => {
   
    setErrorAlertOpen(false);
  }, 4000);
        
      }
    } catch (error) {
      setenviandoAlert(false);
      setErrorAlertOpen(true);
  setAlertMessage('Error al enviar el mensaje, vuelva a intentar.');
    } finally {
      setTimeout(() => {
      setSuccessAlertOpen(false);
      setErrorAlertOpen(false);
    }, 4000);
    }
  };

  

  return (
    <>
     <Container maxWidth="xl" sx={{ display:'flex', alignItems:'center', justifyContent:'center',  ' @media (max-width: 1600px)': {
            paddingLeft:'0px'
          }}}>
      <section  data-aos="fade-down" >
        <Grid container sx={{width:'100%', height:'100%', display:'flex', alignItems:'center'}} >
          <Grid item xs={12} sm={6} sx={{width:'100%', height:'100%', display:'flex', alignItems:'center'}} >
            <div className="contieneimagennuestroequipo" >
            <img src={imagen}   alt="equipos personas axinntus"  title="personas, equipo, axinntus" />
            </div>
          </Grid>
         
          <Grid item xs={12} sm={6} sx={{width:'100%', height:'100%', display:'flex', alignItems:'center'}}>
            {menuVisible ? (  <div className="titulo-nuestro-equipo"  >
              <h1 alt="equipos personas axinntus" title="personas, equipo, axinntus" >¡Sé parte de nuestro equipo de excelencia!</h1>
             
            </div>) : ( 
            <div   className="titulo-nuestro-equipo">
              <h1  alt="equipos personas axinntus" title="personas, equipo, axinntus" >¡Sé parte de </h1>
              <h1  alt="equipos personas axinntus" title="personas, equipo, axinntus" >nuestro equipo </h1>
              <h1  alt="equipos personas axinntus" title="personas, equipo, axinntus" >de excelencia!</h1>
            </div>)}
          </Grid>  

        </Grid>
      </section>
      </Container>

      <Container maxWidth="md" sx={{marginTop:'100px', marginBottom:'50px', display:'flex', alignItems:'center', justifyContent:'center'}}>
      <section   data-aos="fade-down" >
        <div className="info-trabajo" >
          <h1  alt="equipos personas axinntus" title="personas, equipo, axinntus" >Búsqueda de ofertas laborales disponibles</h1>
          <span  alt="equipos personas axinntus" title="personas, equipo, axinntus" >Encuéntranos buscando la palabra “Axinntus” en:</span>
        </div>
        <div className="redes-trabajo"  >
          <div className="red-trabajo"  >
            <div className="icono"  >
              {" "}
              <BsGlobe2  alt="equipos personas axinntus" title="personas, equipo, axinntus"  />
            </div>
            <a
              target="black"
              href="https://cl.computrabajo.com/empresas/ofertas-de-trabajo-de-axinntus-ltda-4A8CDC19F2439C2D"
            >
              <h1 alt="equipos personas axinntus" title="personas, equipo, axinntus"  >computrabajo.cl</h1>
             
            </a> <ArrowForwardIosIcon  alt="equipos personas axinntus" title="personas, equipo, axinntus"  style={{marginLeft:"30px", color:"#6f7170", fontSize:"20px"}}/>
          </div>
          <div   className="red-trabajo">
            <div className="icono"  >
              {" "}
              <BsGlobe2  alt="equipos personas axinntus" title="personas, equipo, axinntus"  />
            </div>
            <a
              target="black"
              href="https://www.bne.cl/ofertas?mostrar=empleo&textoLibre=axinntus&numPaginaRecuperar=1&numResultadosPorPagina=10&clasificarYPaginar=true"
            >
              <h1  alt="equipos personas axinntus" title="personas, equipo, axinntus" >bne.cl</h1>
              
            </a><ArrowForwardIosIcon   alt="equipos personas axinntus" title="personas, equipo, axinntus"  style={{marginLeft:"30px", color:"#6f7170", fontSize:"20px"}}/>
          </div>
        </div>
      </section>
      </Container>
      <Grid container className="formulario-trabajo" data-aos="fade-down" >
      <Container maxWidth="md" sx={{marginTop:'50px', marginBottom:'50px', display:'flex', alignItems:'center', justifyContent:'center'}}>
        <section className="formulario-requerimiento-contieneform-equipo"   >
          <h1  alt="equipos personas axinntus" title="personas, equipo, axinntus" >Si quieres enviarnos tu currículum hazlo aquí</h1>
          <form className="formuequip" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} className="contieneformequipo">
                <StyledTextField
                  className="input-contieneform-equipo"
                  label="Nombre completo"
                  variant="outlined"
                  value={formData.nombre}
                  onChange={(event) =>
                    handleChange("nombre", event.target.value)
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} className="contieneformequipo">
                <StyledTextField
                style={{marginBottom:"20px"}}
                  className="input-contieneform-equipo"
                  label="Rut"
                  variant="outlined"
                  value={formData.rut}
                  onChange={(event) => handleChange("rut", event.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8} className="contieneformequipo">
                <StyledTextField
                
                  className="input-contieneform-equipo"
                  label="Ciudad de procedencia"
                  variant="outlined"
                  type="text"
                  value={formData.ciudad}
                  onChange={(event) =>
                    handleChange("ciudad", event.target.value)
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4} className="contieneformequipo">
                <StyledTextField
                  className="input-contieneform-equipo"
                  label="Cargo al que postula"
                  variant="outlined"
                  type="tex"
                  value={formData.cargo}
                  onChange={(event) =>
                    handleChange("cargo", event.target.value)
                  }
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
            <Grid item xs={12} className="contieneformequipo" style={{marginTop:"20px", marginBottom:"20px"}}>
                <div className="contenedor-imagenes-despues-mensaje">
                  <div className="imagen-clip" >
                    <img src={ClipIcon}  alt="personas, axinntus, equipos"  title="personas, equipo, axinntus"  />
                                      </div>
                  {formData.archivo && (
                    <div className="archivo-adjunto">
                      <span  alt='personas, axinntus, equipos' title='personas, equipo, axinntus' >{formData.archivo.name}</span>
                      <CloseIcon onClick={handleRemoveFile} className="eliminar-archivo" />
                    </div>
                  )}
                  {!formData.archivo && (
                    <span  alt='personas, axinntus, equipos' title='personas, equipo, axinntus' >Adjuntar CV</span>
                  )}
                  <input
                   
                    className="seleccion-imagen-contieneform-equipo"
                    type="file"
                    accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    onChange={handleFileChange}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} className="contieneboton-equipo">
                <button type="submit" className="cv"  >
                  Envíar currículum
                </button>
              </Grid>
            </Grid>
          </form>
        </section>
        </Container>
      </Grid>
      <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
      <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
            <h2 id="modal-title" style={{ fontFamily: 'Compasse', color: '#6fa967', fontSize: '1rem', fontWeight: '600',  marginTop:"-10px" }}>Mensaje enviado Correctamente</h2>
            <p style={{ fontFamily: 'Compasse', color: '#585857', fontSize: '1rem', fontWeight: '600', marginTop:"0px" }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de error */}
      <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle  style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Compasse', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      
      <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: '#6fa967' }} />
            <p style={{ fontFamily: 'Compasse', color: '#6fa967', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando mensaje...
            </p>
          </section>
        </Box>
      </Modal>
    </>
  );
}
