import React, { useState, useEffect } from 'react';
import { Grid, Container } from "@mui/material";
import "../../style/seguridad/foco.css";
import AOS from 'aos';
import 'aos/dist/aos.css';

/* imagenes */
import hoja from "../../img/seguridad/foco/h1.png";
import hoja1 from "../../img/seguridad/foco/h2.png";
import segur from "../../img/seguridad/foco/seguridad.png";
import socioambientales from "../../img/seguridad/foco/socioambientales.png";
import focosegur from "../../img/seguridad/foco/focos-seguridad.png";
import focosambiente from "../../img/seguridad/foco/focos-socioambientales.png";

export default function Seguridadfoco() {
  const [menuVisible, setMenuVisible] = useState(false);

  
  useEffect(() => {
    // Agregar un manejador de redimensión para controlar la visibilidad del menú móvil
    const handleResize = () => {
      if (window.innerWidth <= 599) {
        setMenuVisible(true);
      } else {
        setMenuVisible(false);
      }
    };
  
    // Adjuntar el manejador de redimensionamiento al evento de redimensionamiento del window
    window.addEventListener('resize', handleResize);
  
    // Limpieza del evento al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); 

   useEffect(() => {
    // Inicializa AOS con opciones de configuración si es necesario
    AOS.init({
      duration: 1000, // Duración de las animaciones en milisegundos
      once: true, // Solo realiza las animaciones una vez
    });
  }, []); 

  return (
    <>
      {menuVisible ? (
        // Renderizar el segundo componente aquí cuando el ancho de la ventana es menor o igual a 599
       <>
       
       <section className="foco" data-aos="zoom-in-right" >
       <Container maxWidth="md" sx={{marginTop:'50px', marginBottom:'50px', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
        <section className="parte1" >
          <h1>Focos de seguridad y socioambientales de Ultramar</h1>
          <Grid container  sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
            <Grid item xs={12} sm={6}  sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
              <div className="imagen">
                <img src={segur} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}  sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
              <div className="imagen1">
                <img src={focosegur} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' />
              </div>
            </Grid>
          </Grid>
        </section>
        </Container>
      </section>
      <section className="parte2" data-aos="zoom-in-left">
        <img src={hoja} className="hoja" alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' />
        <div className="hoja1">
          <img src={hoja1} className="hoja1" alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' />
        </div>
      </section>
      <Container maxWidth="md" sx={{marginTop:'50px', marginBottom:'50px', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
      <div className="partecita2">
        <Grid container  sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
          <Grid item xs={12} sm={6}  sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
            <div className="imagen2">
            <img src={socioambientales} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}  sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
            <div className="imagen3">
            <img src={focosambiente} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' />
            </div>{" "}
          </Grid>
        </Grid>{" "}
      </div>
      </Container>
       </>
      ) : (
        <> 
        <Container maxWidth="md" sx={{marginTop:'10px', marginBottom:'10px', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}> 
        <section className="foco" data-aos="fade-down">
        <section className="parte1" data-aos="zoom-in-right">
          <h1>Focos de seguridad y socioambientales de Ultramar</h1>
          <Grid container  sx={{display:'flex', alignItems:'center', justifyContent:'center', marginBottom:'30px'}}>
            <Grid item xs={12} sm={6}  sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
              <div className="imagen">
                <img src={segur}  alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos'/>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}  sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
              <div className="imagen1">
                <img src={focosegur}  alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos'/>
              </div>
            </Grid>
          </Grid>
        </section>
      </section>
      </Container>
      <section className="parte2" >
        <img src={hoja} className="hoja" alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' />
        <div className="hoja1">
          <img src={hoja1} className="hoja1" alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' />
        </div>
      </section>
      <Container maxWidth="md" sx={{marginTop:'10px', marginBottom:'10px', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
      <div className="partecita2"data-aos="zoom-in-left">
        <Grid container  sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
          <Grid  item xs={12} sm={6}  sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
            <div className="imagen2">
              <img src={focosambiente}  alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos'/>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}  sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
            <div className="imagen3">
              <img src={socioambientales}  alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos'/>
            </div>{" "}
          </Grid>
        </Grid>{" "}
      </div>
      </Container>
      </>
      )}
     
    </>
  
  );
}
