
import Portadanuestroequipo from "../../components/nuestro-equipo/portada";
import { Container } from "@mui/material";
import { Helmet } from "react-helmet";


  
export default function Nuestroequipo () {

    return(
        <>
         <Helmet>
        <title>Axinntus | Trabaja con nosotros.</title>
        <meta
          name="description"
          content="Únete a nuestro equipo de excelencia en Axinntus. Encuentra ofertas laborales y envía tu currículum."
        />
        <meta
          name="keywords"
          content="Axinntus, empleos en Axinntus, equipo de excelencia, trabajar en Axinntus, equipos, personas, seguridad, activos"
        />
      </Helmet>
           <Container maxWidth="xxl" sx={{marginTop:'0px', paddingLeft:'0px', paddingRight:'0px', padding:'0px', '@media (max-width: 960px)':{
                marginTop:'54px',paddingLeft:'0px', paddingRight:'0px', padding:'0px',
              },'& .MuiContainer-root':{
                paddingLeft:'0px', paddingRight:'0px', padding:'0px',
              },}} >
       <Portadanuestroequipo/>
       </Container>
        
        </>
    )
}