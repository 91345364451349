
import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Grid, Container } from "@mui/material";
import "../../style/sostenibilidad/inclusion.css";


/* imagenes */
import portada from "../../img/sostenibilidad/inclusion/inclusion-diversidad.png";
import imagen from "../../img/sostenibilidad/inclusion/genero.png";
import imagen1 from "../../img/sostenibilidad/inclusion/multiculturalidad.png";
import imagen2 from "../../img/sostenibilidad/inclusion/generacional.png";
import imagen3 from "../../img/sostenibilidad/inclusion/discapacidad.png";
import imagen4 from "../../img/sostenibilidad/inclusion/diversidad.png";
import imagen5 from "../../img/sostenibilidad/inclusion/justo.png";
import imagen6 from "../../img/sostenibilidad/inclusion/interes.png";
import descarga from "../../img/home/acreditacion/descarga.png";
import pdf1 from "../../img/pdf/POLITICA-DIV-INC-AXNN.pdf";

export default function Sostenibilidadinclusion() {
  useEffect(() => {
    // Inicializa AOS con opciones de configuración si es necesario
    AOS.init({
      duration: 1000, // Duración de las animaciones en milisegundos
      once: true, // Solo realiza las animaciones una vez
    });
  }, []);
  return (
    <Container maxWidth="md" sx={{marginTop:'50px', marginBottom:'100px', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
      <section className="portada-inclusion"  data-aos="fade-down">
        <img src={portada} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' />
        <div className="info-inclusion">
          <span>
            {" "}
            Asumimos el compromiso de promover un buen clima laboral y
            desarrollar y potenciar a nuestras y nuestros colaboradores,
            valorando el mérito y la diversidad. Nos comprometemos también, a
            respetar a cada persona por sus características personales, sin
            hacer diferencias o realizar acciones discriminatorias por{" "}
            <strong>
              género, etnia, raza, nacionalidad, edad, orientación sexual,
              cultura, religión, estado civil, situación socioeconómica,
              afinidad política, apariencia, idioma, situación de discapacidad,
              salud, educación y lugar de origen,
            </strong>{" "}
            todo ello basado en los Principios de Pacto Global y Declaración
            Universal de Derechos Humanos de las Naciones Unidas (ONU).
          </span>
        </div>
      </section>
      <section className="inclusion" data-aos="zoom-in">
        <div className="info-inclusion">
          <h1 className="titulo">Focos prioritarios</h1>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <div className="contiene-difucion-inclusion">
                <img src={imagen} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos'/>
                <h1>Equidad de género</h1>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div className="contiene-difucion-inclusion">
                <img src={imagen1} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos'/>
                <h1>Multiculturalidad</h1>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div className="contiene-difucion-inclusion">
                <img src={imagen2} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos'/>
                <h1>Diversidad generacional</h1>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div className="contiene-difucion-inclusion">
                <img src={imagen3} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' className="dispacidad" />
                <h1 className="dispacidad">Personas en situación de discapacidad</h1>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="info-inclusion">
          <h1 className="titulo">Nuestros Compromisos</h1>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <div className="contieneinclusion">
                <img src={imagen4} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' />
                <h1>Valorando la diversidad</h1>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <div className="contieneinclusion">
                <img src={imagen5} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' />
                <h1>Trato digno y justo</h1>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <div className="contieneinclusion">
                <img src={imagen6} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' className="titulo-difucion" />
                <h1 className="titulo-difucion">Incorporación de nuestros grupos de interés</h1>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="boton-descarga">
       
          <a href={pdf1} download target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: 'inherit'}}>
              <button>
                {" "}
                <div className="icono-descargar">
                  
                  <img src={descarga} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' />{" "}
                </div>{" "}
                Descargar Política de Inclusión y Diversidad
              </button>
            </a>
          
        </div>
      </section>
    </Container>
  );
}
