import React from "react";
import Nosotrosproposito from "../../../components/nosotros/proposito";
import { IoIosArrowForward } from "react-icons/io";
import { Helmet } from "react-helmet";
import { Container } from "@mui/material";





export default function Nosotrosprop() {
  
    return(
        <>
         <Helmet>
        <title>Axinntus | Nuestro propósito, misión y visión para soluciones industriales seguras y multiculturales.</title>
        <meta
          name="description"
          content="Desarrollar soluciones industriales seguras que transformen la forma en que las empresas operan, promoviendo la seguridad y eficiencia en todos los sectores industriales."
        />
        <meta
          name="keywords"
          content="Axinntus, mantenimientos industriales, operación industriales, solucion industriales de seguridad, equipos multiculturalidad, equipos de excelencia, trabajar en Axinntus, personas, seguridad, activos."
        />
      </Helmet>
        <Container maxWidth="xl"sx={{marginTop:'-40px','@media (max-width: 960px)':{
                marginTop:'0px'
              },}} >
             <div 
             className="paginacompleta"
          >
            <div style={{ display:"flex", alignItems:"center", fontSize:"0.8rem" }}> <p style={{ fontWeight:"400", color:"#898b8a"}}> Sobre Nosotros</p> <IoIosArrowForward style={{marginLeft:"10px", marginRight:"10px", color:"#6f7170"}}/> <strong style={{ color:"#6f7170"}}> Propósito,
misión, visión
y valores</strong></div>
            </div> </Container>
       <Nosotrosproposito  />

       </>
    )

}