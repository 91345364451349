import React, { useEffect } from "react";
import { Grid, Container } from "@mui/material";
import "../../style/home/clientes/clientes.css"
import AOS from 'aos';
import 'aos/dist/aos.css';


/* imagenes mineria */
import uno from "../../img/home/cliente/1.jpg"
import dos from "../../img/home/cliente/2.jpg"
import tres from "../../img/home/cliente/3.jpg"
import cuatro from "../../img/home/cliente/4.jpg"
import cinco from "../../img/home/cliente/5.jpg"
import seis from "../../img/home/cliente/6.jpg"

import siete from "../../img/home/cliente/7.jpg"
import ocho from "../../img/home/cliente/8.jpg"
import nueve from "../../img/home/cliente/9.jpg"
import diez from "../../img/home/cliente/10.jpg"
import once from "../../img/home/cliente/11.jpg"
import doce from "../../img/home/cliente/12.jpg"
import trece from "../../img/home/cliente/13.jpg"
import catorce from "../../img/home/cliente/14.jpg"
import quince from "../../img/home/cliente/15.jpg"


export default function Clienteshome() {
  
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duración de las animaciones en milisegundos
      once: true, // Solo realiza las animaciones una vez
    });
  }, []);

  
  const dataMineria = [
    { image: uno, title: "Minería", seo:"servicios industriales" },
    { image: cuatro, title: "Minería" , seo:"servicios industriales"},
    { image: cinco, title: "Minería", seo:"servicios industriales" },
    { image: seis, title: "Minería", seo:"servicios industriales" },
  ];


  const dataEnergia = [
    { image: siete, title: "Energía", seo:"servicios industriales" },
    { image: ocho, title: "Energía", seo:"servicios industriales" },
    { image: nueve, title: "Energía", seo:"servicios industriales" },
    { image: diez, title: "Energía", seo:"servicios industriales" },
  ];

  
  const dataQuimicaTransporte = [
    { image: once, title: "Química y Transporte", seo:"servicios industriales" },
    { image: doce, title: "Química y Transporte", seo:"servicios industriales" },
    { image: trece, title: "Química y Transporte", seo:"servicios industriales" },
    { image: catorce, title: "Química y Transporte", seo:"servicios industriales" },
    { image: quince, title: "Química y Transporte", seo:"servicios industriales" },
  ];
 
  return (
    <section  data-aos="fade-down">
      <Container  maxWidth="md" sx={{marginTop:'50px', marginBottom:'100px', display:'flex', alignItems:'center', justifyContent:'center'}}>
      <div className="contienecliente">
        <section className="titulo-informacion-cliente">
          <h2 alt="servicios industriales">Quienes han confiado en nosotros</h2>
        </section>
        <Grid container className="clienhom">
        
          <Grid item xs={12} sm={6} md={4}>
            <div className="contiene-mineria">
              <h1>Minería</h1>
              {dataMineria.map((item, index) => (
                <img key={index} src={item.image} alt={item.seo}   title={item.seo}/>
              ))}
            </div>
          </Grid>

         
          <Grid item xs={12} sm={6} md={4}>
            <div className="contiene-mineria">
              <h1 alt="servicios industriales">Energía</h1>
              {dataEnergia.map((item, index) => (
                <img key={index} src={item.image} alt={item.seo}    title={item.seo}/>
              ))}
            </div>
          </Grid>

       
          <Grid item xs={12} sm={6} md={4}>
            <div className="contiene-mineria">
              <h1 alt="servicios industriales">Química y Transporte</h1>
              {dataQuimicaTransporte.map((item, index) => (
                <img key={index} src={item.image}  alt={item.seo}    title={item.seo} />
              ))}
            </div>
          </Grid>
        </Grid>
      </div>
      </Container>
    </section> 
  );
}