
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "../../style/seguridad/portada.css";
import { Grid , Container} from "@mui/material";

import { IoIosArrowForward } from "react-icons/io";
/* imagenes */
import imagen from "../../img/seguridad/portada/centro.png";
import imagen1 from "../../img/seguridad/portada/proteccion.png";
import imagen2 from "../../img/seguridad/portada/icono-liderazgo-visible.png";
import imagen3 from "../../img/seguridad/portada/compromisos.png";
import imagen4 from "../../img/seguridad/portada/satisfaccion-cliente.png";

export default function Seguridadportada() {
  useEffect(() => {
    // Inicializa AOS con opciones de configuración si es necesario
    AOS.init({
      duration: 1000, // Duración de las animaciones en milisegundos
      once: true, // Solo realiza las animaciones una vez
    });
  }, []);
  return (
    <>
      <section className="contiene-seguridad" style={{ background: "#e6e7e7" }}>
      <Container maxWidth="xl"sx={{marginTop:'0px', '@media (max-width: 960px)':{
                marginTop:'-48px'
              },}}>
        <div
          className="paginascompleta"
          style={{
            position: "absolute",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "0.8rem",
            }}
          >
            {" "}
            <p style={{ fontWeight: "400", color: "#898b8a" }}>
              {" "}
              Seguridad
            </p>{" "}
            <IoIosArrowForward
              style={{
                marginLeft: "10px",
                marginRight: "10px",
                color: "#6f7170",
              }}
            />
            <strong style={{ color: "#6f7170" }}>
              Política de Seguridad, Salud Ocupacional, Medioambiente y Calidad
            </strong>
          </div>
        </div>
        </Container>
        <Container maxWidth="md" sx={{marginTop:'100px', marginBottom:'100px', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
        <section className="titulo-seguridad">
          <div data-aos="fade-right" style={{paddingRight:"15px"}}>
            <h1>Política de Seguridad, </h1>
            <h1>Salud Ocupacional, Medioambiente y Calidad</h1>
          </div>{" "}
          <div className="descripciontotal">
            {" "}
            <span>
              En Axinntus, buscamos promover la salud ocupacional y garantizar
              la seguridad,{" "}
              <strong>
                protegiendo el medioambiente en todas nuestras operaciones.
              </strong>
            </span>
            <span>
              Nuestro enfoque integral nos ha permitido establecer sólidos
              estándares y prácticas que nos distinguen como líderes en la
              industria.
            </span>
            <span>
              Nos comprometemos a{" "}
              <strong> mantener un entorno de trabajo seguro,</strong> prevenir
              accidentes y enfermedades laborales, y minimizar nuestro impacto
              ambiental.
            </span>
          </div>
        </section>
       </Container>
      </section>
      <Container maxWidth="md" sx={{marginTop:'100px', marginBottom:'100px', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
      <section className="politicas">
        <section className="titulo-politica">
        
          <span>
            Ser la empresa{" "}
            <strong>
              {" "}
              líder de servicios industriales de Chile es nuestra visión,
            </strong>{" "}
            reconocida por entregar operaciones{" "}
            <strong>seguras y eficientes</strong> que aportan a mejorar la
            calidad de vida de las persona.
          </span>
          <p></p>
          <span>
            En Axinntus{" "}
            <strong>
              la satisfacción del cliente, la salud y seguridad ocupacional{" "}
            </strong>{" "}
            de nuestros colaboradores y colaboradoras, junto con el{" "}
            <strong>cuidado y protección del medioambiente</strong> son nuestros
            principales valores. Creemos firmemente en el pleno cumplimiento de
            los requerimientos de nuestros clientes, en la{" "}
            <strong>
              {" "}
              meta de cero incidentes de seguridad, cero enfermedades
              profesionales y cero incidentes medioambientales.
            </strong>
            <strong></strong> Los siguientes son nuestros compromisos,
            convencidos que son indispensables para asegurar el desarrollo
            sostenible de nuestras operaciones:
          </span>
        </section>

        <Grid container data-aos="fade-down" className="contieneseguridad">
          <Grid item xs={12} sm={6}>
            <div className=" contiene-cerd">
              <img src={imagen} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos'/>
              <div className="info">
                <h2>Vida al Centro</h2>
                <span>
                  Buscamos mejorar continuamente los estándares de Seguridad y
                  Salud Ocupacional en nuestras operaciones, promoviendo una
                  Cultura de Excelencia en Seguridad que pone la vida de las
                  personas al centro de lo que hacemos.
                </span>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className=" contiene-cerd">
              <img src={imagen4} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' />
              <div className="info">
                <h2>Satisfacción del Cliente</h2>
                <span>
                  Buscamos permanentemente reconocer las necesidades de nuestros
                  clientes, de manera tal de ser un socio estratégico que
                  agregue valor a sus operaciones, asegurando la entrega de
                  recursos, la gestión del riesgo de la calidad y la mejora
                  continua de nuestros procesos, que aseguren colaboradores y
                  colaboradoras competentes y equipamiento de vanguardia.
                </span>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className=" contiene-cerd">
              <img src={imagen2} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' />
              <div className="info">
                <h2>Liderazgo Visible, Sentido y con Impacto</h2>
                <span>
                  Estamos comprometidos a todo nivel para impulsar y empoderar a
                  nuestros equipos, ejerciendo y promoviendo un estilo de
                  liderazgo presente en terreno, participativo de toda la
                  compañía, consecuente y comprometido con la seguridad del área
                  donde trabajamos.
                </span>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className=" contiene-cerd">
              <img src={imagen1} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' />
              <div className="info">
                <h2>Cuidado y Protección del Medio Ambiente</h2>
                <span>
                  Respetamos al medio ambiente evitando la contaminación,
                  controlando los impactos de nuestras operaciones, gestionando
                  adecuadamente los residuos y haciendo uso eficiente de los
                  recursos naturales, promoviendo así la mejora continua en
                  nuestra gestión ambiental.
                </span>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className=" contiene-cerd">
              <img src={imagen3} alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' />
              <div className="info">
                <h2>Compromisos y Requisitos Normativos</h2>
                <span>
                  Cumplimos con las Disposiciones Legales, regulaciones y otros
                  requisitos atingentes a las operaciones donde participamos,
                  tanto en materia de Seguridad, Salud Ocupacional y Medio
                  Ambiente.
                </span>
              </div>
            </div>
          </Grid>
        </Grid>
        <div className="final-politica">
          <h2>
            Todos quienes formamos parte de Axinntus vivimos estos compromisos
            en nuestro diario accionar y en cada toma de decisión.
          </h2>
        </div>
      </section>
      </Container>
    </>
  );
}
