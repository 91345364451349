import Logisticas from "../../../components/servicios/logistica/logistica";
import { IoIosArrowForward } from "react-icons/io";
import { Container } from "@mui/material";
import { Helmet } from "react-helmet";




export default function Logistica() {

    return(
        <>
        <Helmet>
        <title>Axinntus | Logística interna y transferencia de material.</title>
        <meta
          name="description"
          content="En Axinntus, garantizamos la logística interna y transferencia de material mediante operaciones industriales seguras y eficientes, con equipos de excelencia y multiculturalidad, en un entorno de seguridad activa y mantenimientos industriales rigurosos."
        />
        <meta
          name="keywords"
          content="Axinntus, mantenimientos industriales, operación industriales, equipos multiculturalidad, equipos de excelencia, trabajar en Axinntus, personas, seguridad, activos."
        />
      </Helmet>
         <Container maxWidth="xl" sx={{marginTop:'-40px','@media (max-width: 960px)':{
                marginTop:'0px'
              },}} >
        <div
        className="paginacompleta"
   
  >
    <div
      style={{ display: "flex", alignItems: "center", fontSize: "0.8rem" }}
    >
      {" "}
      <p style={{ fontWeight: "400", color: "#898b8a" }}>
        {" "}
        Nuestros servicios
      </p>{" "}
      <IoIosArrowForward
        style={{
          marginLeft: "10px",
          marginRight: "10px",
          color: "#6f7170",
        }}
      />{" "}
      <strong style={{ color: "#6f7170" }}>
     
Logística interna
y transferencia
de material</strong>
    </div>
  </div>
</Container>
        <Logisticas/>
        </>
    )
}