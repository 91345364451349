import React, { useEffect } from "react";
import { Grid, Container } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import "../../style/home/certificaciones/certificacion.css";
import AOS from 'aos';
import 'aos/dist/aos.css';

/* imagenes */
import logo from "../../img/home/acreditacion/certificaciones.jpg";
import logo3 from "../../img/home/acreditacion/acreditaciones.jpg";
import descarga from "../../img/home/acreditacion/descarga.png";
import pdf from "../../img/pdf/BROCHURE-AXNN-2024.pdf";
import pdf1 from "../../img/pdf/libro.pdf";

export default function Certificacionhome() {
  useEffect(() => {
    // Inicializa AOS con opciones de configuración si es necesario
    AOS.init({
      duration: 1000, // Duración de las animaciones en milisegundos
      once: true, // Solo realiza las animaciones una vez
    });
  }, []);

  return (
    <section>
      <Container
        maxWidth="md"
        sx={{ marginTop: '100px', marginBottom: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Grid container data-aos="zoom-in">
          <Grid item xs={12} sm={6}>
            <div className="certificaciones">
              <h2>Certificaciones</h2>
              <div className="iconos-certificaciones">
                <img src={logo} alt="Axinntus" />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="certificaciones">
              <h2>Acreditaciones</h2>
              <div className="iconos-acreditacion">
                <img src={logo3} alt="Axinntus" />
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>

      <Grid container>
        <Grid item xs={12} sm={6} className="brochure">
          <div className="brochure-info">
            <h2>Brochure</h2>
            <h3>Comercial</h3>
            <a href={pdf} download target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: 'inherit'}}>
              <button className="boton-descarga" variant="outlined">
                <div className="icono-descargar">
                  <img src={descarga} alt="servicios operación axinntus" />
                </div>
                Descargar
              </button>
            </a>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} className="libro">
          <div className="brochure-info">
            <h2>Libro</h2>
            <h3>10 años</h3>
            <a href={pdf1} download target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: 'inherit'}}>
              <button className="boton-descarga" variant="outlined">
                <div className="icono-descargar">
                  <img src={descarga} alt="servicios operación axinntus" />
                </div>
                Descargar
              </button>
            </a>
          </div>
        </Grid>
      </Grid>

      <Container
        maxWidth="xxl"
        sx={{
          width: "100%",
          height: "70vh",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: "0px",
          paddingLeft: "0px",
          paddingRight: "0px",
          '@media (max-width: 600px)': {
            paddingLeft: "0px",
            paddingRight: "0px",
            '& .MuiContainer-root': {
              paddingLeft: "0px",
              paddingRight: "0px"
            }
          },
          '& .MuiContainer-root': {
            paddingLeft: "0px",
            paddingRight: "0px"
          }
        }}
      >
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/x1o7Y_iCce0?autoplay=1&mute=1"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </Container>
    </section>
  );
}