import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Container } from "@mui/material";
import { Helmet } from "react-helmet";
import Nosotroshistoria from "../../../components/nosotros/historia";

  

export default function Nosotroshist() {
    return(
        <>
         <Helmet>
        <title>Axinntus | Nuestra historia y trayectoria.</title>
        <meta
          name="description"
          content="La dedicación y capacidad de nuestros equipos de trabajo, pudimos mirar hacia el futuro y expandirnos hacia las soluciones industriales en las que nuestros servicios eran un valor agregado para mejorar la productividad, principalmente en la minería."
        />
        <meta
          name="keywords"
          content="Axinntus, mantenimientos industriales, operación industriales, solucion industriales de seguridad, equipos multiculturalidad, equipos de excelencia, trabajar en Axinntus, personas, seguridad, activos, servicios."
        />
      </Helmet>
            <Container maxWidth="xl" sx={{marginTop:'145px','@media (max-width: 960px)':{
                marginTop:'0px'
              },}} >
  <div className="paginacompleta" >
            <div style={{ display:"flex", alignItems:"center", fontSize:"0.8rem" }}> <p style={{ fontWeight:"400", color:"#898b8a"}}> Sobre Nosotros</p> <IoIosArrowForward style={{marginLeft:"10px", marginRight:"10px", color:"#6f7170"}}/> <strong style={{ color:"#6f7170"}}> Nuestra historia y trayectoria</strong></div>
            </div></Container>
       <Nosotroshistoria  />

        
        </>
    )

}