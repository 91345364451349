
import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import "../../style/sostenibilidad/relansamiento.css";
import { Grid, Container } from "@mui/material";
import { IoIosArrowForward } from "react-icons/io";


/* imagenes */
import imagen from "../../img/sostenibilidad/relansamiento/interes.png";
import imagen1 from "../../img/sostenibilidad/relansamiento/dual.png";
import imagen2 from "../../img/sostenibilidad/relansamiento/entorno.png";
import imagen3 from "../../img/sostenibilidad/relansamiento/corporativo.png";

export default function Sostenibilidadrelansamiento() {
  useEffect(() => {
    // Inicializa AOS con opciones de configuración si es necesario
    AOS.init({
      duration: 1000, // Duración de las animaciones en milisegundos
      once: true, // Solo realiza las animaciones una vez
    });
  }, []);
  return (
    <section className="program-comunitario">
      <section className="fondo" data-aos="fade-down">
      <Container maxWidth="XL"sx={{marginTop:'-20px'}} >
      <div >
    <div       className="paginascompleta"
      style={{ display: "flex", alignItems: "center", fontSize: "0.8rem", position:"absolute" }}
    >
      {" "}
      <p style={{ fontWeight: "400", color: "#898b8a" }}>
        {" "}
       Sostenibilidad
      </p>{" "}
      <IoIosArrowForward
        style={{
          marginLeft: "10px",
          marginRight: "10px",
          color: "#6f7170",
        }}
      />{" "}
      <strong style={{ color: "#6f7170" }}>
 
Relacionamiento con grupos de interés</strong>
    </div>
  </div>
  </Container>

  <Container maxWidth="md" sx={{marginTop:'50px', marginBottom:'100px', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
        <div className="relansamiento" >
        <Grid container >
          <Grid item xs={12} sm={6}>
            <div className="imagen"  data-aos="zoom-in">
              <img src={imagen}  alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} >
            <div className="descripcion">
              <div className="titulo-info">
              <h1>Relacionamiento con grupos de interés</h1>
            </div>
            
              <span className="info">
                A través de distintas acciones, la compañía lleva a cabo una
                estrategia que involucra a los principales grupos de interés. Se
                evalúa la acción y la forma en cómo relacionarse a diario,
                principalmente con las comunidades donde se encuentran las
                operaciones de sus clientes.{" "}
              </span>
            
            <div className="segundo">
              <span>
                Por medio de diversas iniciativas y programas, buscamos generar
                un impacto positivo y sostenible, manteniendo un diálogo
                constante con cada uno de ellos.
              </span>
            </div>
            </div>
          </Grid>
        </Grid>
        </div>
        </Container>
      </section>
      <Container maxWidth="md" sx={{marginTop:'50px', marginBottom:'100px', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
      <section className="programa" data-aos="fade-down">
        <div className="titulo-programa">
          <h1>Programas Comunitarios</h1>
        </div>
        <section className="todo-programa">
          <div className="programa">
            <div className="icono-programa">
              <img src={imagen1}  alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos' />
              <p>Contigo y tu entorno</p>
            </div>
            <div className="informacion-programa">
              <ul className="listado">
                <li>
                  Charlas técnicas que buscan que los alumnos de los distintos
                  centros educacionales cercanos a las operaciones de Axinntus,
                  se informen acerca de la importancia de prevenir los posibles
                  riesgos en sus actividades diarias y se capaciten en diversas
                  materias de seguridad y medioambiente.
                </li>
                <li>
                  {" "}
                  Las charlas son dictadas por los mismos colaboradores y otros
                  expertos.
                </li>
                <li>
                  {" "}
                  Programa implementado desde el año 2019, ha beneficiado a más
                  de 300 personas.
                </li>
              </ul>
            </div>
          </div>
          <div className="programa" data-aos="fade-down">
            <div className="icono-programa">
              <img src={imagen2}  alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos'/>
              <p>Formación Dual</p>
            </div>
            <div className="informacion-programa">
              <ul className="listado">
                <li>
                  Permite a estudiantes de centros educacionales técnicos llevar
                  a cabo parte de sus horas académicas al interior de las
                  operaciones de la compañía.
                </li>
                <li>
                  Gracias a los convenios y alianzas ejecutadas con distintos
                  establecimientos, los alumnos cuentan con tutores que los
                  acompañaron durante todo su proceso de enseñanza.
                </li>
              </ul>
            </div>
          </div>
          <div className="programa" data-aos="fade-down">
            <div className="icono-programa">
              <img src={imagen3}   alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos'/>
              <p>Voluntariado corporativo</p>
            </div>
            <div className="informacion-programa">
              <ul className="listado">
                <li>
                  Para Axinntus es importante establecer vínculos permanentes
                  con las comunidades y una manera de reforzar esos vínculos es
                  a través del voluntariado corporativo.
                </li>
                <li>
                  Éste permite promover e involucrar libremente a los
                  colaboradores con la comunidad con diferentes iniciativas como
                  entrega de donaciones, hermoseamiento de infraestructuras,
                  apoyos en actividades benéficas, etc.
                </li>
              </ul>
            </div>
          </div>
        </section>
      </section>
      </Container>
    </section>
  );
}
