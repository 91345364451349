import Mantenimientoindustrial from "../../../components/servicios/mantenimiento/mantenimientoindustrial";
import { IoIosArrowForward } from "react-icons/io";
import { Container } from "@mui/material";
import { Helmet } from "react-helmet";



export default function Mantenimiento() {

    return(
        <>
         <Helmet>
        <title>Axinntus | "Mantenimiento industrial y gestión de activos.</title>
        <meta
          name="description"
          content="Axinntus ofrece servicios de mantenimiento industrial y gestión de activos para centrales termoeléctricas y puertos mecanizados, incluyendo sistemas de transporte y almacenamiento de graneles sólidos y ácido sulfúrico."
        />
        <meta
          name="keywords"
          content="Axinntus, activos, mantenimiento industriales, servicios, centrales termoeléctricas, puertos mecanizados, transporte de graneles sólidos, almacenamiento de ácido sulfúrico, gestión de activos, servicios de sistemas de manejo de residuos, grúas, infraestructura portuaria."
        />
      </Helmet>
         <Container maxWidth="xl" sx={{marginTop:'-40px','@media (max-width: 960px)':{
                marginTop:'0px'
              },}} >
            <div className="paginacompleta"
    
  >
    <div
      style={{ display: "flex", alignItems: "center", fontSize: "0.8rem" }}
    >
      {" "}
      <p style={{ fontWeight: "400", color: "#898b8a" }}>
        {" "}
        Nuestros servicios
      </p>{" "}
      <IoIosArrowForward
        style={{
          marginLeft: "10px",
          marginRight: "10px",
          color: "#6f7170",
        }}
      />{" "}
      <strong style={{ color: "#6f7170" }}>
     
Mantenimiento
industrial y
gestión de activos</strong>
    </div>
  </div></Container>
<Mantenimientoindustrial/>
        </>
    )
}