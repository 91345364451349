
import React, {  useEffect } from 'react';
import { Grid, Container } from "@mui/material";
import "../../style/nosotros/portada.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
/* imagenes */
import lupa from "../../img/nosotros/portada/lupa.png";

export default function Nosotrosportada() {
  useEffect(() => {
    // Inicializa AOS con opciones de configuración si es necesario
    AOS.init({
      duration: 1000, // Duración de las animaciones en milisegundos
      once: true, // Solo realiza las animaciones una vez
    });
  }, []); 
  return (
    <Container maxWidth="md" sx={{marginTop:'50px', marginBottom:'50px', display:'flex', alignItems:'center', justifyContent:'center'}}>
    <section className="paginanosotros">
      <Grid container className="contienerodalaportadanosotros">
        <Grid item xs={12} sm={6} data-aos="fade-right">
          <div className="contiene-portada-quienes-somos">
            <div className='contieneiamgennosotros'>
            <img src={lupa} alt="axinntus multiculturalidad, activos"  title='multiculturalidad de equipos personas, activos ' />
            </div>
            <h1 alt="axinntus multiculturalidad, activos" title='multiculturalidad de equipos personas, activos '>Quiénes </h1>
            <h1 alt="axinntus multiculturalidad, activos" title='multiculturalidad de equipos personas, activos '>somos</h1>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="informacion-nosotros">
            <span alt="multiculturalidad" title='multiculturalidad de equipos personas '>
              Somos una empresa especializada <strong> en la entrega de servicios
              industriales seguros, confiables y de excelencia,</strong> para las
              industrias de <strong>minería, energía y química,</strong> a través de personas y
              equipos altamente calificados, enfocados en sostenibilidad e
              innovación.
            </span>
            <span alt=" multiculturalidad" title='multiculturalidad de equipos personas '>
              La cultura organizacional que nos caracteriza <strong>como parte del grupo
              de empresas de Ultramar,</strong> es poner a las personas en el centro de
              lo que hacemos, entablar relaciones de confianza y transparentes
              con nuestros grupos de interés, preocupándonos por las comunidades
              y el entorno donde operamos, y por sobre todo, entregar un
              servicio de excelencia que nos distinga.
            </span>
            <span alt=" multiculturalidad" title='multiculturalidad de equipos personas activos '>
              Nuestros <strong>8 contratos activos con importantes empresas </strong> en las
              industrias de minería, energía y química son el resultado de
              nuestra dedicación, compromiso y capacidad para cumplir con los
              más altos estándares en cada proyecto.
            </span>
          </div>
        </Grid>
      </Grid>
    </section>
    </Container>
  );
}
