import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { Container } from "@mui/material";
import "../../style/home/servicios/servicioshome.css"
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {useNavigate } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { styled } from '@mui/system';

import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';

/* imagenes */
import solidos from "../../img/home/servicios/tres.png"
import soluciones from "../../img/home/servicios/soluciones.jpg"
import interna from "../../img/home/servicios/interna.png"
import mantenimiento from "../../img/home/servicios/dos.png"
import industrial from "../../img/home/servicios/cuatro.png"
import dron from "../../img/home/servicios/dron.png"


const serviciosData = [
    {
      id: 1,
      title: "Manejo de ",
      title1: "Graneles Sólidos",
      description: "Recepción, acopio, abastecimiento, inventario, carguío y despacho de...",
      image: solidos,
      link: "/Servicios/ManejoSolidos",
    },
    {
      id: 2,
      title: "Logística interna y transferencia de material",
      description: "Ejecutamos la recepción, descarga, clasificación, preparación, acopio...",
      image: interna,
      link: "/Servicios/Logistica",
    },
    {
        id: 3,
        title: "Limpieza industrial ",
        title1: "",
        description: "Enmarcados en altos estándares de seguridad y cuidado del entorno...",
        image: industrial,
        link: "/Servicios/Limpiezaindustrial",
      },
      {
        id: 4,
        title: "Mantenimiento industrial",
        description: "Mantención de las principales centrales termoeléctricas del país y puertos...",
        image: mantenimiento,
        link: "/Servicios/Mantenimiento",
      },
     
      {
        id: 6,
        title: "Otras soluciones industriales a medida",
        description: "Realizamos servicios de requerimientos esporádicos, proyectos a corto plazo...",
        image: soluciones,
        link: "/Servicios/Soluciones",
      },
       {
        id: 7,
        title: "Limpieza de Superficies y",
        title1: "Estructuras industriales con dron",
        description: "servicio que se puede adaptar a cualquier lugar y estructura, brindando una operación segura y con altos estándares de excelencia operacional....",
        image: dron,
        link: "/Servicios/Limpieza",
      }, 
    // Agrega más servicios según sea necesario
  ];



export default function Serviciohome() {
  const navigate = useNavigate();
 useEffect(() => {
    // Inicializa AOS con opciones de configuración si es necesario
    AOS.init({
      duration: 1000, // Duración de las animaciones en milisegundos
      once: true, // Solo realiza las animaciones una vez
    });
  }, []);  

  const handleClick = (link) => {
    navigate(link); // Navegar a la ruta especificada
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Desplazamiento suave a la parte superior
  };
    return(
      
      <section className="contienen-servicio-home">
        <Container maxWidth="md" sx={{marginTop:'30px'}}>
       
          <div className="contienentodo">
      <section className="contienen-titulo-servicio-home">
        <div className="titulo-servicio-home">
        <h2 className="titulo-nuestro-servicio-home"   alt="servicios axinntus" title="axinntus, servicios, seguridad, mantenimiento, operacion, activos, multiculturalidad">
          Nuestros Servicios
        </h2></div>
      </section>
      <Grid container >
        {serviciosData.map((servicio) => (
          <Grid key={servicio.id} item xs={6} sm={6} md={4} sx={{width:'100%', display:'center', alignItems:'center', justifyContent:'center', padding:'10px', marginBottom:'30px', flexDirection:'column'}}>
            <Card sx={{ width:'100%', height:'400px', flexDirection:'column', display:'flex', borderRadius:'10px', '@media (max-width: 900px)':{
                height:'500px',
              }, '@media (max-width: 800px)':{
                height:'460px',
              }, '@media (max-width: 695px)':{
                height:'430px',
              },
              '@media (max-width: 600px)':{
                height:'100%',
              },}} > 
              <CardActionArea>
                <img src={servicio.image}  alt="equipos personas industriales servicios seguridad mantenimiento operacion activos multiculturalidad axinntus"  title="personas, equipos, axinntus,  industriales, servicios, seguridad, mantenimiento, operacion, activos, multiculturalidad" className="imageneserviciohome"  />
                <CardContent  sx={{width:'100%', display:'flex', alignItems:'flex-start',height:'150px', flexDirection:'column','@media (max-width: 400px)':{
    padding:'5px'
  },}} >
                  <StyledTypographytitle gutterBottom variant="h5" component="div" sx={{marginBottom:'0px'}}>
                    {servicio.title}
                  </StyledTypographytitle>
                  <StyledTypographytitle gutterBottom variant="h5" component="div"   >
                    {servicio.title1}
                  </StyledTypographytitle>
                  <StyledTypography variant="body2" color="text.secondary" sx={{ marginTop:'10px'}}  >
                    {servicio.description}
                  </StyledTypography>
                </CardContent>
              </CardActionArea>
              <CardActions sx={{width:'100%', display:"flex", alignItems:"center", justifyContent:"center"}}>
               
                <Button size="small" color="primary" className="buton-servicio"  onClick={() => handleClick(servicio.link)}>
                  Ver Más
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      </div>
    </Container> 
   
    </section> 
   
    )
} 
const StyledTypographytitle= styled(Typography)(({ theme, error }) => ({
 
  fontSize:'1rem',
  color: '#55565a',
  fontFamily: 'Compasse',
  textTransform:'none',
  lineHeight:'1.1',
  fontWeight:'bold',
  
  '@media (max-width: 800px)':{
    fontSize:'0.9rem',
  },
  '@media (max-width: 600px)':{
    fontSize:'0.7rem',
  },
 
}));

const StyledTypography = styled(Typography)(({ theme, error }) => ({
 
  fontSize:'0.85rem',
  color: '#55565a',
  fontFamily: 'Compasse',
  textTransform:'none',
  lineHeight:'1.1',
  
  '@media (max-width: 800px)':{
    fontSize:'0.8rem',
  },
  '@media (max-width: 600px)':{
    fontSize:'0.7rem',
  },
  '@media (max-width: 400px)':{
    fontSize:'0.6rem',
  },
})); 