import { IoIosArrowForward } from "react-icons/io";
import { Container } from "@mui/material";
import { Helmet } from "react-helmet";
import Seguridadregla from "../../../components/seguridad/reglas";



export default function Seguridadreglas() {
   
    return(
        <>
         <Helmet>
        <title>Axinntus | Reglas de oro.</title>
        <meta
          name="description"
          content="Para el Cuidado de la Vida y la protección del entorno socioambiental aplicando medios de seguridad a nuestros equipos."
        />
        <meta
          name="keywords"
          content="axinntus, operación industriales, servicios , personas, estándares de seguridad, servicios a la medida, eficiencia."
        />
      </Helmet>
         <Container maxWidth="xl" sx={{marginTop:'150px','@media (max-width: 960px)':{
                marginTop:'70px'
              },}} >
             <div
             className="paginacompleta"
    style={{
      margin: "0px",
    }}
  >
    <div
      style={{ display: "flex", alignItems: "center", fontSize: "0.8rem" }}
    >
      {" "}
      <p style={{ fontWeight: "400", color: "#898b8a" }}>
        {" "}
        Seguridad
      </p>{" "}
      <IoIosArrowForward
        style={{
          marginLeft: "10px",
          marginRight: "10px",
          color: "#6f7170",
        }}
      />{" "}
      <strong style={{ color: "#6f7170" }}>Reglas de oro</strong>
    </div>
  </div></Container>
      <Seguridadregla/>
        </>
    )
}