import { IoIosArrowForward } from "react-icons/io";
import { Container } from "@mui/material";
import { Helmet } from "react-helmet";
import Sostenibilidadportada from "../../../components/sostenibilidad/portada";






export default function Sostenibilidadport() {

  return (
    <div>
        <Helmet>
        <title>Axinntus | Pilares de Sostenibilidad</title>
        <meta
          name="description"
          content="Únete a nosotros en nuestro viaje hacia un futuro sostenible, donde combinamos valores de negocio con la preservación del planeta."
        />
        <meta
          name="keywords"
          content="axinntus, operación industriales, servicios , personas, estándares de seguridad, servicios a la medida, operación."
        />
      </Helmet>
        <Container maxWidth="xl"> 
        <div
        className="paginacompleta"
    
  >
    <div
      style={{ display: "flex", alignItems: "center", fontSize: "0.8rem" }}
    >
      {" "}
      <p style={{ fontWeight: "400", color: "#898b8a" }}>
        {" "}
       Sostenibilidad
      </p>{" "}
      <IoIosArrowForward
        style={{
          marginLeft: "10px",
          marginRight: "10px",
          color: "#6f7170",
        }}
      />{" "}
      <strong style={{ color: "#6f7170" }}>
 
Pilares de Sostenibilidad</strong>
    </div>
  </div>
</Container>
 <Sostenibilidadportada/>
    </div>
  );
}