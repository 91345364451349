import React from "react";
import Nosotrospersonas from "../../../components/nosotros/personas";
import { IoIosArrowForward } from "react-icons/io";
import { Container } from "@mui/material";
import { Helmet } from "react-helmet";


export default function Nosotrospers() {
  return (
    <>
     <Helmet>
        <title>Axinntus | Profesionales apasionados en soluciones industriales de vanguardia.</title>
        <meta
          name="description"
          content="En Axinntus, nuestros equipos multiculturalidad y de excelencia trabajan en mantenimientos industriales y operaciones industriales seguras, ofreciendo soluciones de seguridad y servicios de calidad para personas activas y comprometidas con la seguridad."
        />
        <meta
          name="keywords"
          content="Axinntus, mantenimientos industriales, operación industriales, solucion industriales de seguridad, equipos multiculturalidad, equipos de excelencia, trabajar en Axinntus, personas, seguridad, activos, servicios."
        />
      </Helmet>
     <Container maxWidth="xl"sx={{marginTop:'145px','@media (max-width: 960px)':{
                marginTop:'0px'
              },}} >
      <div
      className="paginacompleta"
       
      >
        <div
          style={{ display: "flex", alignItems: "center", fontSize: "0.8rem" }}
        >
          {" "}
          <p style={{ fontWeight: "400", color: "#898b8a" }}>
            {" "}
            Sobre Nosotros
          </p>{" "}
          <IoIosArrowForward
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              color: "#6f7170",
            }}
          />{" "}
          <strong style={{ color: "#6f7170" }}> Nuestras personas</strong>
        </div>
      </div></Container>

      <Nosotrospersonas />
    </>
  );
}
