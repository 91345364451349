
import React, { useEffect, useState } from 'react';
import "../../style/nosotros/personas.css";
import { Grid, Container } from "@mui/material";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useInView } from 'react-intersection-observer';

/* imagenes */
import logo from "../../img/nosotros/personas/seccion-nuestras-personas.png";
import discapacidad from "../../img/nosotros/personas/discapacidad.png";
import dotacionfemenina from "../../img/nosotros/personas/dotacion-femenina.png";
import multiculturalidad from "../../img/nosotros/personas/multiculturalidad.png";

/* imagenes equipo */
import florencia from "../../img/nosotros/personas/florencia-marquez.png";
import eve from "../../img/nosotros/personas/evelyn-bizama.png";
import michael from "../../img/nosotros/personas/michael-espinoza.png";
import paula from "../../img/nosotros/personas/paula-espinosa.png";

import mauricio from "../../img/nosotros/personas/mauricio-bustos.png";
import mau from "../../img/nosotros/personas/maurizio-gecele.png";
import camila from "../../img/nosotros/personas/camila-subiabre.png";
import macarena from "../../img/nosotros/personas/ejecutivo-macarena-palacios.png"

/* imagenes admi */
import cristian  from "../../img/nosotros/personas/cristian-aracena.png" 
import rodri  from "../../img/nosotros/personas/rodrigo-castillo.png"
import sol  from "../../img/nosotros/personas/soledad-asencio.png"
import fran  from "../../img/nosotros/personas/ejecutivo-francisco-ossandon.png"
import jorge from "../../img/nosotros/personas/jorge-castillo.png"
import busqueda  from "../../img/nosotros/personas/ejecutivo-busqueda.png"
import marco  from "../../img/nosotros/personas/marcos-venegas.png"

const CountingComponent = ({ isVisible, targetCount, step, interval }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let intervalId;
    if (isVisible) {
      intervalId = setInterval(() => {
        setCount(prevCount => Math.min(prevCount + step, targetCount));
      }, interval);
    }

    return () => clearInterval(intervalId);
  }, [isVisible, targetCount, step, interval]);

  return <span>{count}</span>;
};


export default function Nosotrospersonas() {

  const [ref2, inView2] = useInView({
    triggerOnce: true,
    threshold: 0,  // Establecer el umbral a 0 para que se active cuando aparezca en la pantalla
  });

  const Datas = [
     { id: 1, imagen: dotacionfemenina, titulo:"Dotación", title:" femenina", count:"12" },
     { id: 2, imagen: discapacidad, titulo:"Personas en situación", title:" de discapacidad",  count:"1" },
    { id: 3, imagen: multiculturalidad, titulo:"Multiculturalidad", count:"11" },
  
  ];

  
  const Dataperosna = [
    { id: 1, imagen: florencia },
    { id: 2, imagen: eve },
    { id: 3, imagen: michael },
    { id: 4, imagen: paula }, 
    { id: 5, imagen: macarena },
    { id: 6, imagen: mauricio },
    { id: 7, imagen: mau },
    { id: 8, imagen: camila},
  ];

  const Dataadmi = [
    { id: 1, imagen: cristian },
    { id: 2, imagen: rodri },
    { id: 3, imagen: sol },
    { id: 4, imagen: fran},
    { id: 5, imagen: jorge },
    { id: 6, imagen: marco },
    { id: 7, imagen: busqueda },
   
  ];
  useEffect(() => {
    // Inicializa AOS con opciones de configuración si es necesario
    AOS.init({
      duration: 1000, // Duración de las animaciones en milisegundos
      once: true, // Solo realiza las animaciones una vez
    });
  }, []); 
  return (
    <Container maxWidth="md" sx={{marginTop:'50px', marginBottom:'100px', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
      <section className="titulo-personas">
        <div className="titulo-principal" data-aos="fade-right">
         <div className='imagenpersonaslogo'> <img src={logo} alt='personas, multiculturalidad, equipos'  title='personas, multiculturalidad, equipos'  /></div>
          <h1 alt='personas, multiculturalidad, equipos' title='personas, multiculturalidad, equipos'>Nuestras personas</h1>
        </div>
        <div className="informacion-prinicipal">
          <span>
            Contamos con <strong> más de 700 colaboradores</strong> apasionados
            y dedicados que conforman nuestra familia Axinntus.
          </span>
          <hr />
          <span>
            Quienes están{" "}
            <strong>
              altamente capacitados en diversas disciplinas industriales{" "}
            </strong>
            y poseen una amplia experiencia en sus respectivos campos. Su
            conocimiento especializado nos permite abordar los desafíos más
            exigentes en la industria, siempre con un enfoque en la eficiencia y
            la calidad.
          </span>
        </div>
      </section>

      <section className="dotacion" ref={ref2}>
        <Grid container className="info-dotacion">
        {Datas.map((item) => (
          <Grid xs={12} sm={4}  key={item.id}>
            <div className="contiene-card">
             <div className='contieneimgenpersonas'>
              
              <div className='contieneiconopersonas'>
             <img src={item.imagen} alt='personas, multiculturalidad, equipos'  title='personas, multiculturalidad, equipos' /></div>
            <strong><span> <CountingComponent
                    isVisible={inView2}
                    targetCount={item.count}
                    step={Math.ceil(item.count / 100)}
                    interval={100}
                  />%</span></strong>
</div>
              <h3>{item.titulo}</h3>
              <h3>{item.title}</h3>
            </div>
          </Grid>
            ))}
        </Grid>
      </section>
      <section className="equipos">
        <section className="principal-equipo">
          <h3>Equipo Ejecutivo</h3>
        </section>
        <Grid container spacing={2} className="contiene-person" >
      {Dataperosna.map((persona) => (
        <Grid key={persona.id} xs={12} sm={6} md={3} data-aos="zoom-in-down">
          <div className="imagen-persona">
            <img src={persona.imagen} alt='personas, multiculturalidad, equipos'  title='personas, multiculturalidad, equipos' />
          </div>
        </Grid>
      ))}
    </Grid>
      </section>
      <section className="equipos">
        <section className="principal-equipo">
          <h3>Administradores de Contratos - Servicios transitorios (Spot)</h3>
        </section>
        <Grid container spacing={2}className="contiene-person">
      {Dataadmi.map((persona) => (
        <Grid key={persona.id} xs={12} sm={6} md={3} className="personascontiene" data-aos="zoom-in-down">
          <div className="imagen-persona">
            <img src={persona.imagen} alt='personas, multiculturalidad, equipos'  title='personas, multiculturalidad, equipos' />
          </div>
        </Grid>
      ))}
    </Grid>
      </section>
    </Container>
    
  );
}
