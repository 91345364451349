
import React, { useState, useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Grid, Typography} from "@mui/material";

import"../../style/sostenibilidad/galeria.css"

/* imagenes */
import imagen from "../../img/sostenibilidad/galeria/1.jpg"
import imagen2 from "../../img/sostenibilidad/galeria/2.jpg"
import imagen3 from "../../img/sostenibilidad/galeria/3.jpg"
import imagen4 from "../../img/sostenibilidad/galeria/4.jpg"
import imagen5 from "../../img/sostenibilidad/galeria/5.jpg"
import imagen6 from "../../img/sostenibilidad/galeria/6.jpg"
import imagen7 from "../../img/sostenibilidad/galeria/7.jpg"
import imagen8 from "../../img/sostenibilidad/galeria/8.jpg"
import imagen9 from "../../img/sostenibilidad/galeria/9.jpg"
import icono from "../../img/sostenibilidad/galeria/zoom.png"

const Datagaleria =[
    {
        id:1,
        imagen:imagen,
        titulo:"Mesa de Convergencia Local de Mejillones"
    },
    {
        id:2,
        imagen:imagen2,
        titulo:"Charlas técnicas a colegio Don Bosco de Antofagasta"
    },
    {
        id:3,
        imagen:imagen3,
        titulo:"Operativo limpieza de playas"
    },
    {
        id:4,
        imagen:imagen4,
        titulo:"Apadrinamiento Aula Hospitalaria de Mejillones"
    },
    {
        id:5,
        imagen:imagen5,
        titulo:"Jardín infantil Sonrisas de Colores de Hualpén"
    },
    {
        id:6,
        imagen:imagen6,
        titulo:"Operativo limpieza de playas en Coronel"
    },
    {
        id:7,
        imagen:imagen7,
        titulo:" Paseo familiar al desierto florido en Huasco"
    },
    {
        id:8,
        imagen:imagen8,
        titulo:"Jardín infantil y Sala Cuna Reconquista de Hualpén"
    },
    {
        id:9,
        imagen:imagen9,
        titulo:"Escuela Básica Campiche de Puchuncaví"
    },
    

]

export default function Sostenibilidadgaleria() {
  useEffect(() => {
    // Inicializa AOS con opciones de configuración si es necesario
    AOS.init({
      duration: 1000, // Duración de las animaciones en milisegundos
      once: true, // Solo realiza las animaciones una vez
    });
  }, []);
    const [popupVisible, setPopupVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openPopup = (image) => {
    setSelectedImage(image);
    setPopupVisible(true);
  };

  const closePopup = () => {
    setSelectedImage(null);
    setPopupVisible(false);
  };
  return (
    <section className="segurgle">
      <section className="titulo-galeria" data-aos="fade-down">
        <h1>Galería de fotos</h1>
        <span>Actividades más destacadas</span>
      </section>
      <section className="galeria">
      <Grid container >
          {Datagaleria.map((item) => (
            <Grid key={item.id} item xs={12} sm={6} md={4} className="gal-foto" data-aos="zoom-in">
               <div className="imagen-galeria" onClick={() => openPopup(item.imagen)}>
                <img
                  src={item.imagen}
                  width={'100%'}
                  height={'100%'}
                  alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos'
                  
                />
                <div className="titulo-overlay">
                  <Typography  component="div" className="titulo">
                    {item.titulo}
                  </Typography>
                  <img
                    src={icono}
                    alt="axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos"  title='axinntus servicios, industriales, seguridad, mantenimiento, operación, equipos'
                    className="icono-zoom"
                  />
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </section>
     
      {popupVisible && (
        <div className="popup-overlay" onClick={closePopup}>
          <div className="popup-contentgaleria">
            {selectedImage && (
                <div  className="imagenesgaleria">
              <img
                src={selectedImage}
                width={'100%'}
                height={'100%'}
                alt="axinntus"
                className="imagen-galeria"
               
              /></div>
            )}
           
          </div>
        </div>
      )}
    </section>
  );
}
