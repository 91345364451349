import Manejos from "../../../components/servicios/manejosolidos/manejosolidos";
import { IoIosArrowForward } from "react-icons/io";
import { Container } from "@mui/material";
import { Helmet } from "react-helmet";



export default function Manejosolidos() {

  return (
    <>
    <Helmet>
        <title>Axinntus | Manejo de graneles sólidos.</title>
        <meta
          name="description"
          content="Axinntus ofrece operación y mantenimiento industrial de graneles sólidos con equipos de excelencia y multiculturalidad, asegurando seguridad y eficiencia para el desarrollo económico."
        />
        <meta
          name="keywords"
          content="Axinntus, mantenimientos industriales, operación industriales, equipos multiculturalidad, equipos de excelencia, trabajar en Axinntus, personas, seguridad, activos."
        />
      </Helmet>
      <Container maxWidth="xl" sx={{marginTop:'-40px','@media (max-width: 960px)':{
                marginTop:'0px'
              },}} >
        <div
          className="paginacompleta"

        >
          <div
            style={{ display: "flex", alignItems: "center", fontSize: "0.8rem" }}
          >
            {" "}
            <p style={{ fontWeight: "400", color: "#898b8a" }}>
              {" "}
              Nuestros servicios
            </p>{" "}
            <IoIosArrowForward
              style={{
                marginLeft: "10px",
                marginRight: "10px",
                color: "#6f7170",
              }}
            />{" "}
            <strong style={{ color: "#6f7170" }}>

              Manejo de
              graneles sólidos</strong>
          </div>
        </div>
      </Container>
      <Manejos />
    </>
  )
}